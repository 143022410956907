<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 position-relative">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="display-4 text-white fw-normal">Saldo opnemen</h1>
            <hr />

            <div v-if="participants.length > 1">
                <div class="col">
                    <div class="btn btn-light" @click="toggleParticipants"><i class="fa fa-sync me-1" id="changeParticipant"></i> Deelname wisselen</div>
                </div>

                <div class="change-participant w-100" style="display: none;">
                    <h4 class="text-white fw-normal">Deelname wisselen</h4>
                    <select class="form-select" id="participantsList" @change="changeParticipantIndex">
                        <option v-for="(participant, index) in participants" :key="participant.id" :value="participant.id" :selected="index === 0">
                            {{ participant.subgroup?.customer_description || participant.userlog_name }} (max {{ this.$numberFormatEuro(participant.max_amount) }})
                        </option>
                    </select>
                </div>
            </div>

            <h4 class="display-6 text-white mt-3 fw-normal">Saldo</h4>

            <h3 class="mb-0 p-3 bg-trans display-1 text-light rounded-4 display-1 position-relative">
                <div>
                    <span :class="['d-block max-balance reloadParticipant', currentSaldoParticipant ? 'text-white' : 'text-transparent'] " id="balance">
                        {{ this.$numberFormatEuro(parseFloat(participants[currentParticipantIndex].saldo)) }}
                    </span>
                </div>
            </h3>

            <p class="text-white pt-2">Voor deze periode mag je maximaal <span class="rounded-1 p-1 mx-1 bg-info text-dark small reloadParticipant" :key="currentParticipantIndex">{{ this.$numberFormatEuro(maxAmount) }}</span> opnemen. Dit bedrag bestaat uit het niet-storneerbare deel van je saldo.</p>

            <p class="p-3 bg-trans-black text-light rounded-2 small">
                <i class="fa fa-wallet me-1"></i> Dit is het bedrag dat je op dit moment maximaal uit je saldo kunt opnemen. De laatste 2 maanden inleg dienen te blijven staan, vanwege de wettelijke storneringstermijn bij automatische incasso van 56 dagen voor consumenten.
            </p>

            <hr/>

            <div id="balanceForm" class="mb-3">
                <h4 class="fw-bold text-white m-0 mt-3">Op te nemen saldo</h4>
                <div class="row mt-3">
                    <div class="col-3 col-md-5">
                        <p class="fw-bold text-white mb-0 mt-2">Bedrag</p>
                    </div>
                    <div class="col">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="border-radius: 4px 0 0 4px;">&euro;</span>
                            </div>
                            <input type="number" class="form-control reloadParticipant" step="0.01" min="0" :max="maxAmount" v-model="request_payout_amount">
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="row">
                    <div class="col">
                    <button @click="submitForm" class="btn btn-primary" :disabled="maxAmount < request_payout_amount"><i class="fa fa-arrow-right me-1"></i> Saldo opnemen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Modal v-for="modal in modals" :key="modal.title" :title="modal.title" :content="modal.content"
        :button_text="modal.button_text" :button_icon="modal.button_icon" :url="modal.url" :modal_type="modal.modal_type" :question_enum="modal.question_enum" :additional_class="modal.additional_class"
        @closeModal="closeModal" @initiatePayout="initiatePayout" />
</template>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import { getCustomerStorage } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Modal from '@/components/ModalComponent.vue';
import {useToast} from 'vue-toastification';

export default {
    name: 'saldo-component',
    components: {
        Breadcrumbs,
        Modal
    },
    data() {
        return {
            modals: [],
            request_payout_amount: 0,
            minimum_payout: 5,
            totalRequest: 0,
            participants: [
                {
                    id: 0,
                    end_at: null,
                    start_at: null,
                    saldo: 0,
                    saldo_confirmed: 0,
                    saldo_sure: 0,
                    subgroup_id: 0,
                }
            ],
            currentParticipantIndex: 0,
            maxAmount: 0,

            iban_payment: '',
            breadcrumbs: [new Breadcrumb("/saldo", "Mijn spaarpot"), new Breadcrumb("/saldo/withdraw", "Saldo opnemen")],
        }
    },
    methods: {
        async getParticipantData() {
            // const response = await this.$http.get("/participants");
            // const data = response.data;
            let participants = [];
            let decrease = 0;
            await this.$http.get("/participants/all").then((response) => {
                let data = response.data;
                let has_saldo_sure = false;

                // kijken of er minimaal één deelname met niet-storneerbaar saldo is.
                for(let i = 0; i < data.length; i++) {
                    const feature = data[i].subgroup.group.feature;
                    if(feature === 'to_user_saldo' || feature === 'aov_participant_saldo' || feature === 'aov_particiant_saldo') {
                        if(data[i].saldo_sure < 0) {
                            decrease += data[i].saldo_sure;
                        }
                    }
                }
                for(let i = 0; i < data.length; i++) {
                    const feature = data[i].subgroup.group.feature;
                    if(feature === 'to_user_saldo' || feature === 'aov_participant_saldo' || feature === 'aov_particiant_saldo') {
                        if(data[i].saldo_sure - decrease > 0) {
                            has_saldo_sure = true;
                        }
                    }
                }

                // alle deelnames ophalen met storneerbaar saldo, of als het er geen zijn, dan alle tonen
                if(!has_saldo_sure) {
                    participants.push(data[0]);
                } else {
                    for(let i = 0; i < data.length; i++) {
                        const feature = data[i].subgroup.group.feature;
                        if(feature === 'to_user_saldo' || feature === 'aov_participant_saldo' || feature === 'aov_particiant_saldo') {
                            if(data[i].saldo_sure > 0) {
                                participants.push(data[i]);
                            }
                        }
                    }
                }
            });
            
            if(participants.length) {
                let maxSaldoSure = 0;

                this.maxAmount = 0;
                this.currentParticipantIndex = 0;
                
                this.participants = participants;

                this.active_payout_requests = [];
                
                participants.forEach(function(participant, index) {
                    let requestedAmount = 0;
                    participant.active_payout_requests.forEach(function(payout_request) {
                        if(payout_request.type == 'payout_once') {
                            requestedAmount += payout_request.amount;
                        }
                    });
                    participants[index].max_amount = Math.max(0, participant.saldo_sure + decrease - requestedAmount);

                    if(maxSaldoSure < participant.saldo_sure) {
                        this.currentParticipantIndex = index;
                        
                        this.request_payout_amount = (participant.saldo_sure + decrease - requestedAmount).toFixed(2);
                        this.maxAmount = Math.max(0, participant.saldo_sure + decrease - requestedAmount);

                        participant.active_payout_requests.forEach(function(payout_request) {
                            if(payout_request.type == 'payout_once') {
                                this.active_payout_requests.push(payout_request);
                            }
                        }.bind(this));

                        maxSaldoSure = participant.saldo_sure;
                    }
                }.bind(this));
                this.totalRequest = 0;
                this.participants[this.currentParticipantIndex].active_payout_requests.forEach((payout) => {
                    this.totalRequest += payout.amount;
                });
            }

            $("#balance").show("slow");
        },
        toggleParticipants() {
            $(".change-participant").toggle(400);
            $("#changeParticipant").addClass("rotate-once");
            setTimeout(() => {
                $("#changeParticipant").removeClass("rotate-once");
            }, 600);
        },
        submitForm() {
            let requestedAmount = 0;
            this.participants[this.currentParticipantIndex].active_payout_requests.forEach(function(payout_request) {
                if(payout_request.type == 'payout_once') {
                    requestedAmount += payout_request.amount;
                }
            });

            if(this.request_payout_amount >= this.minimum_payout && this.request_payout_amount <= this.participants[this.currentParticipantIndex].saldo_sure - requestedAmount) {
                this.modals.push(this.$createModal("Saldo laten storten", `Weet je zeker dat je ${this.$numberFormatEuro(this.request_payout_amount)} wilt opnemen?\nJe ontvangt het geld vandaag of de eerstvolgende werkdag op je rekening.`, "fa-check", "Ja", "/disability", this.$ModalType.Question, "initiatePayout"));
            } else if(this.request_payout_amount < this.minimum_payout) {
                const toast = useToast();
                toast.error(`Je moet minimaal ${this.$numberFormatEuro(this.minimum_payout)} opnemen om een uitbetalingsverzoek in te dienen`, {
                    icon: "fa fa-times"
                });
            } else {
                const toast = useToast();
                toast.error("Je hebt onvoldoende vrij opneembaar saldo om je uitbetalingsverzoek in te dienen", {
                    icon: "fa fa-times"
                });
            }
        },
        initiatePayout() {
            this.$http.post("/participants/" + this.participants[this.currentParticipantIndex].id + "/add_payout_request", {
                amount: this.request_payout_amount,
            }).
            then(() => {
                this.$router.push('/withdrawal-confirmation');
            }).
            catch((error) => {
                console.log(error);
                this.modals.push(this.$createModal("Bedrag te laag", `Je hebt onvoldoende vrij opneembaar saldo om je uitbetalingsverzoek in te dienen.`, "fa-check", "OK", "/disability", this.$ModalType.Notification));
            })
        },
        closeModal() {
            this.modals[0].additional_class = 'disappear';
            setTimeout(() => {
                this.modals = [];
            }, 500);
        },
        changeParticipantIndex() {
            let selectedParticipant = parseInt($("#participantsList").find(":selected").val());

            $('.reloadParticipant').fadeOut(0);
            $('.reloadParticipant').fadeIn(400);
            
            this.participants.forEach((participant, index) => {
                if(participant.id === selectedParticipant) {
                    this.currentParticipantIndex = index;
                }
            });

            this.active_payout_requests = [];

            let requestedAmount = 0;
            let participant = this.participants[this.currentParticipantIndex];

            participant.active_payout_requests.forEach(function(payout_request) {
                if(payout_request.type == 'payout_once') {
                    requestedAmount += payout_request.amount;
                }
            });
            
            this.request_payout_amount = (participant.saldo_sure - requestedAmount).toFixed(2);
            this.maxAmount = participant.saldo_sure - requestedAmount;

            participant.active_payout_requests.forEach(function(payout_request) {
                if(payout_request.type == 'payout_once') {
                    this.active_payout_requests.push(payout_request);
                }
            }.bind(this));

            this.totalRequest = 0;
            this.participants[this.currentParticipantIndex].active_payout_requests.forEach((payout) => {
                this.totalRequest += payout.amount;
            });
        },
        toggleSaldoInformation() {
            $("#saldoInformation").toggle(400);
        }
    },
    mounted() {
        $('#balance').hide();
        $('#saldoInformation').hide();

        let customer = getCustomerStorage().getItem("customer");
        if(customer) {
            this.iban_payment = customer.iban_payment
        }

        this.getParticipantData();
    }
}
</script>

<style scoped>
.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.bg-trans-black {
    background-color: rgba(0, 0, 0, 0.2);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.hide {
    max-height: 0;
}

.edit-withdrawal:focus {
    outline: 0;
}

.edit-withdrawal {
    transition: color var(--transition-400ms) ease-in-out;
}

.bg-trans {
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
}

.not-possible {
    color: #FF8888;
}

.bg-green {
    background-color: var(--light-green);
}

.bg-no-balance {
    background-color: rgba(255, 199, 199);
}

.rotate-once {
    animation: rotate-180 400ms linear forwards;
}

@keyframes rotate-180 {
    0% {
        transform: 0;
    }

    100% {
        transform: rotateZ(180deg);
    }
}
</style>