<template>
  <div class="w-100 m-0 d-flex justify-content-center">
    <div class="container-inputs p-3 position-relative">
      <h4 class="display-5 text-white fw-normal">Login</h4>
      <form class="space-y-4" @submit.prevent="submit">
        <div>
          <div>
            <label for="email" class="text-sm text-white">Email</label>
          </div>
          <input type="email" id="email" name="email" v-model="email" ref="email"
            class="w-100 px-2 py-2 mt-2 mb-3 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            autocomplete="username" />
        </div>

        <div>
          <div>
            <label for="password" class="text-sm text-white">Wachtwoord</label>
          </div>
          <input type="password" id="password" name="password" v-model="password"
            class="w-100 px-2 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            autocomplete="current-password" />
        </div>

        <div class="d-flex my-3">
          <input type="checkbox" class="large-checkbox me-1" id="rememberme">
          <label for="rememberme" class="text-white m-0">Onthoud mij op dit apparaat</label>
        </div>

        <div>
          <button type="submit"
            class="px-2 py-2 tracking-wide text-white transition-colors duration-200 btn btn-primary w-100">
            Login
          </button>
        </div>
      </form>

      <div class="hr-subtle"></div>

      <p class="mt-8 text-xs font-light text-center text-white">
        Deelnemer worden? <a href="https://zelfstandigondernemers.nl/" class="text-white" target="_blank">Meld je aan via de
          website</a>
      </p>
      <p class="mt-8 text-xs font-light text-center text-gray-700">
        <router-link to="/forgot-password" class="mt-8 text-xs font-light text-center text-white">
          Wachtwoord vergeten?
        </router-link>
      </p>
    </div>
  </div>
</template>

<style scoped>
.hr-subtle {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 1px;
}

.large-checkbox {
  width: 1.3em;
  height: 1.3em;
}

a {
  text-decoration: underline;
}
</style>

<script type="text/javascript">

import { setCustomer } from '@/App.vue';
import $ from 'jquery';

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    async submit() {
      const remember = $("#rememberme").is(":checked");
      

      try {
        const response = await this.$http.post('/login', {
          email: this.email,
          password: this.password,
        });
    
        localStorage.clear();
        sessionStorage.clear();

        let storage;
        if(remember) {
          storage = localStorage;
          localStorage.setItem('customerStorage', 'localStorage');
        } else {
          storage = sessionStorage;
          localStorage.setItem('customerStorage', 'sessionStorage');
        }
        
        storage.setItem('token', response.data.access_token);
        setCustomer(response.data.customer);

        this.emitter.emit('login', true);

        if (response.data.customer.twofactorauthentication_method == 'yubikey') {
          await this.$router.push({ path: '/2fa/yubikey' });
        } else if (response.data.customer.twofactorauthentication_method == 'authenticator') {
          await this.$router.push({ path: '/2fa/authenticator' });
        } else {
          await this.$router.push({ path: '/2fa/sms' });
        }
      } catch (error) {
        console.error(error)

        // this.email = ''
        this.password = ''

        this.$refs.email.focus()
      }
    }
  },
  mounted() {
    this.$refs.email.focus()
  }
};
</script>
