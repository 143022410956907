<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs"></Breadcrumbs>
            <h1 class="text-white display-4 fw-normal fade-all">Yubikey instellen</h1>
            <hr />
            <p class="text-white fade-all">Gebruik uw Yubikey om 2-factor-authenticatie in te stellen. Zorg ervoor dat het <strong>Code-veld</strong> is geselecteerd.</p>

            <ol class="text-white border-subtle position-relative py-3 pe-3 fade-all">
                <li>Steek uw Yubikey in een usb-poort op uw apparaat</li>
                <li>Houd de gouden knop op de Yubikey gedurende 1 seconde ingedrukt</li>
            </ol>

            <hr />

            <form id="authenticatorSetup" class="fade-all position-relative" @submit.prevent="submit">
                <div class="row row-cols-1 my-3">
                    <div class="col-3">
                        <label for="newPassword" class="text-white fw-bold mt-2">Code</label>
                    </div>
                    <div class="col-9">
                        <input type="password" id="authenticatorCode" class="form-control w-100" placeholder="Code" :value="yubikeyCode" @input="event => yubikeyCode = event.target.value" autofocus>
                    </div>
                </div>

                <button class="btn btn-primary"><i class="fa fa-unlock me-1"></i> Activeren</button>
            </form>
        </div>
    </div>
</template>

<script type="text/javascript">
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { useToast } from 'vue-toastification';
import $ from 'jquery';

export default {
    name: "setup-yubikey",
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/account', 'Account'), new Breadcrumb('/account/setup-yubikey', 'Yubikey')],
            yubikeyCode: "",
        }
    },
    methods: {
        async submit() {
            const toast = useToast();
            this.$http.post('set_up/yubikey', {
                passcode: this.yubikeyCode
             })
             .then((response) => {
                this.yubikeyCode = "";
                toast.success(response.data["alert-success"], {
                    icon: "fa fa-check",
                });
                this.$router.push('/account');
             }).
             catch(() => {
                this.yubikeyCode = "";
             });
        },
    },
    mounted() {
        $(".fade-all").fadeOut(0);
        $(".fade-all").fadeIn(800);
        $("#authenticatorCode").focus();
    },
}
</script>

<style scoped>
.border-subtle {
    border: 1px solid rgba(255, 255, 255, 0.2);
}
</style>