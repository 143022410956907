<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h1 class="display-4 text-white fw-normal">Gelukt!</h1>
            <hr />
            <p class="text-white">Je opnameverzoek is bij ons binnengekomen.</p>
            <p class="text-white">Je saldo wordt <strong class="p-1 rounded-1 bg-info text-dark mx-1">vandaag of uiterlijk de eerstvolgende werkdag</strong> naar je bij ons bekende rekeningnummer overgemaakt.</p>

            <!-- <p class="text-light p-3 rounded-3 bg-primary">
                <i class="fa fa-lightbulb me-2"></i>Heb je het geld direct nodig? Neem dan contact met ons op.
            </p> -->

            <div class="text-center">
                <marquee direction="right">
                    <img src="@/assets/dance.gif">
                </marquee>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';

export default {
    name: "gelukt-component",
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/saldo', 'Mijn spaarpot'), new Breadcrumb('/saldo/withdraw', 'Saldo opnemen'), new Breadcrumb('/withdrawal-confirmation', 'Gelukt')]
        }
    }
}
</script>

<style scoped>
.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}
</style>