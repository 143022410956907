<template>
    <div class="d-flex justify-content-center position-relative">
        <div v-for="link in calculatedPagination" :key="link.label" :class="['d-inline-block p-1 border border-info pagination text-white', link.label === '>' || calculatedPagination.length === 1 ? 'border-right' : '', currentPaginationPage === link.label ? 'pagination-current-active bg-info' : '']"
                @click="() => emitEvent(link)">
            {{ link.label }}
        </div>
    </div>
</template>

<script type="text/javascript">

export default {
    name: 'pagination-component',
    props: ['pagination', 'currentPaginationPage'],
    data() {
        return {}
    },
    methods: {
        updatePagination() {
            this.$emit('updatePagination');
        },
        emitEvent(link) {
            if(this.currentPaginationPage !== link.label) {
                this.$emit('updatePagination', link.url, link.label)
            }
        }
    },
    computed: {
        calculatedPagination() {
            let paginationGroup = this.pagination;
            if(paginationGroup.length === 3)
                return [paginationGroup[1]];
            return paginationGroup;
        }
    },
}
</script>

<style scoped>
</style>

