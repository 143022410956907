<template>
    <div class="w-100 m-0 d-flex justify-content-center position-relative">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <div class="row">
                <div class="col">
                    <h4 class="display-4 text-white fw-normal">Ziekmelding</h4>
                </div>
                <div class="col">
                    <div class="btn btn-secondary float-end mt-4" @click="changeStatusToBetter"><i class="fa fa-heart me-1"></i> Beter melden</div>
                </div>
            </div>

            <hr/>

            <!-- Update geven -->
            <div class="w-100 disability">
                <form id="updateForm" @submit.prevent="updateDisability">
                    <div class="row row-cols-1 p-0 position-relative mb-3">
                        <div class="col w-100" id="reminder">
                            <p class="p-3 rounded-3 bg-warning small">
                                <i class="fa fa-exclamation me-1"></i> Let op! Het is meer dan twee weken geleden sinds je voor het laatst een update over je ziekmelding hebt verstuurd.
                            </p>
                        </div>

                        <div class="col" v-if="disability">
                            <p class="text-white mb-1">
                                <strong>Duur arbeidsongeschiktheid: </strong> {{ monthDiff(new Date(disability.start_at), new Date()) }} {{ monthDiff(new Date(disability.start_at), new Date()) === 1 ? 'maand' : 'maanden' }}
                            </p>
                            <p class="text-white mb-1">
                                <strong>Verwacht beter te zijn op: </strong> {{ disability.expected_end_at ? $formatDate(disability.expected_end_at) : '-' }}
                            </p>
                            <p class="text-white">
                                <strong>Volgende update: </strong> {{ disability && disability.customer_update_expected_at ? $formatDate(disability.customer_update_expected_at) : 'Onbekend' }}
                            </p>

                            <hr class="dashed"/>
                        </div>

                        <div class="col" v-if="lastUpdate">
                            <p class="text-white small">Laatste update: {{ $formatDate(lastUpdate.created_at) }}</p>
                        </div>

                        <!-- Beschrijving ziekmelding -->
                        <div v-if="disability && disability.description">
                            <div class="col">
                                <h6 class="text-white mb-2">Bechrijving</h6>
                            </div>
                            <div class="col">
                                <p class="text-white" v-html="disability.description"></p>
                            </div>
                            <div class="col">
                                <hr/>
                            </div>
                        </div>

                        <div class="col">
                            <h6 class="text-white mb-2">Update</h6>
                        </div>
                        <div class="col">
                            <textarea class="form-control" cols="4" rows="4" style="resize: none;"
                                v-model="update" placeholder="Voer hier een update in over je ziekmelding." required></textarea>
                        </div>
                    </div>

                    <!-- Status update over ziekmelding (3 knoppen) -->
                    <div class="row row-cols-1 p-0 position-relative">
                        <div class="col">
                            <h6 class="text-white mb-2">Hoe voel je je ten opzichte van de vorige update?</h6>
                        </div>
                    </div>

                    <div class="row row-cols-3">
                        <div class="col">
                            <div :class="['btn btn-danger d-block update-type', updateAmount !== -1 ? 'opacity-75 border-transparent' : 'opacity-100 border-selected']" @click="() => updateAmount = -1"><img src="@/assets/svg/line-down.svg" class="icon invert"> Slechter</div>
                        </div>
                        <div class="col">
                            <div :class="['btn btn-light d-block update-type', updateAmount !== 0 ? 'opacity-75 border-transparent' : 'opacity-100 border-selected']" @click="() => updateAmount = 0"><i class="fa fa-equals me-1"></i> Gelijk</div>
                        </div>
                        <div class="col">
                            <div :class="['btn btn-secondary d-block update-type', updateAmount !== 1 ? 'opacity-75 border-transparent' : 'opacity-100 border-selected']" @click="() => updateAmount = 1"><img src="@/assets/svg/line-down.svg" class="icon flip"> Beter</div>
                        </div>
                    </div>

                    <div class="row p-2 position-relative">
                        <div class="col p-1">
                            <a :class="['btn btn-primary', isValid ? '' : 'opacity-75']" @click="updateDisability" id="updateButton"><i class="fa fa-save" id="updateIcon"></i> Update geven</a>
                        </div>
                    </div>
                </form>
                <hr>

                <h4 class="text-white fw-normal">Arbeidsongeschiktheidsverloop</h4>

                <div class="p-3 rounded-2 text-start small text-dark bg-info mb-2">
                    <div>
                        <i class="fa fa-info-circle me-1"></i> Dit is het verloop van je arbeidsongeschiktheid op basis van bevindingen en vastleggingen van een arbo&#8209;arts of de casemanager.
                    </div>
                    <div class="m-0 mt-2" id="doctor-updates">
                        <i class="fa fa-times me-1"></i> Er zijn nog geen gegevens ingevuld door een arbo-arts.
                    </div>
                </div>

                <table class="table table-hover" v-if="disabilityCourse.length > 0">
                    <thead>
                        <tr>
                            <th class="text-white">Datum</th>
                            <th class="text-white">Beschrijving</th>
                            <th class="text-white">Percentage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="course in disabilityCourse" :key="course.id">
                            <td class="text-white">{{ $formatDate(course.medical_examination_done_at) }}</td>
                            <td class="text-white" v-html="course.description || 'Geen beschrijving'"></td>
                            <td class="text-white">{{ course.disability_part * 100 }}&percnt;</td>
                        </tr>
                    </tbody>
                </table>

                <h4 class="text-white fw-normal">Updates</h4>

                <div class="btn btn-primary my-2" @click="toggleUpdates" v-if="disabilityCustomerUpdates.length > 0"><i :class="['fa fa-chevron-down me-1', openUpdates ? 'rot-180' : '']"></i> Toon updates</div>
                <div id="updates" class="w-100">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <td class="text-white">Datum</td>
                                <td class="text-white">Beschrijving</td>
                                <td class="text-white">Voortgang</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="update in disabilityCustomerUpdates" :key="update.id">
                                <td class="text-white">{{ $formatDate(update.created_at) }}</td>
                                <td class="text-white" v-html="update.description "></td>
                                <td :class="['text-center position-relative', update.development === -1 ? 'text-danger text-brighter bg-worse' : 'text-white bg-neutral', update.development === 1 ? 'text-success bg-better' : '']"><i :class="['fa middle', icons[update.development+1]]"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Als er geen updates verzonden zijn -->
                <p class="p-3 rounded-2 bg-info text-dark small" v-if="disabilityCustomerUpdates.length === 0"><i class="fa fa-info-circle me-1"></i> Je hebt nog geen updates over deze ziekmelding verzonden.</p>
            </div>
        </div>
    </div>
    <Modal v-for="modal in modals" :key="modal.title" :title="modal.title" :content="modal.content"
        :button_text="modal.button_text" :button_icon="modal.button_icon" :url="modal.url" :modal_type="modal.modal_type" :question_enum="modal.question_enum" :additional_class="modal.additional_class"
        @closeModal="closeModal" @reportBetter="updateStatusToBetter" @sendUpdate="sendUpdate" />
</template>

<script type="text/javascript">
/* eslint-disable*/

import $ from "jquery";
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Modal from '@/components/ModalComponent.vue';
import { Breadcrumb, MessageType } from '@/App.vue';
import { useToast } from 'vue-toastification';
// import { getCustomerStorage } from '@/App.vue';

export default {
    name: 'disability-component',
    components: {
        Breadcrumbs,
        Modal
    },
    data() {
        return {
            disability: undefined,
            disabilities: [],
            disabilityCourse: [],
            updateAmount: -2,
            lastUpdate: undefined,
            sliderColor: ["slider-red", "slider-blue", "slider-green"],
            breadcrumbs: [new Breadcrumb("/disability", "Mijn ziekmeldingen"), new Breadcrumb('', 'Ziekmelding')],
            disabilityCustomerUpdates: [],
            openUpdates: false,
            update: "",
            modals: [],
            icons: ['fa-arrow-down', 'fa-minus', 'fa-arrow-up'],
        }
    },
    computed: {
        isValid: function() {
            return this.updateAmount > -2 && this.update !== "";
        }
    },
    methods: {
        async getDisabilites() {
            // Haal ziekmeldingen op uit de database
            await this.$http.get('disabilities')
                .then((response) => {
                    this.responseData = response.data;
                    this.disabilities = this.responseData.data;

                    this.disability = undefined;
                    this.disabilities.forEach((element) => {
                        if(element.id === parseInt(this.$route.params.id)) {
                            this.disability = element;
                        }
                    });

                    this.disabilityCourse = this.disability.course_of_disabilities.reverse();
                    this.disabilityCustomerUpdates = this.disability.disability_customer_updates.reverse();

                    if(this.disabilityCourse.length === 0)
                        $("#doctor-updates").show(400);

                    if(this.disability) {
                        if(this.disability.customer_update_expected_at && new Date(this.disability.customer_update_expected_at) < new Date())
                            $("#reminder").show(400);
                    }
            });
        },
        closeModal() {
            this.modals[0].additional_class = 'disappear';
            setTimeout(() => {
                this.modals = [];
            }, 50);
        },
        monthDiff(d1, d2) {
            let months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        },
        changeStatusToBetter() {
            this.modals.push(
                this.$createModal("Beter melden", "Weet je zeker dat je je beter wilt melden?", "fa-check", "Ja", "/disability", this.$ModalType.Question, "reportBetter"));
        },
        async updateStatusToBetter() {
            this.modals = [];
            await this.$http.post(`disabilities/${this.disability.id}/end`)
                .then(() => {
                    this.modals.push(
                        this.$createModal("Beter gemeld!", "Bedankt voor je betermelding! Fijn dat je er weer bent!", "fa-check", "OK!", "/disability", this.$ModalType.Confirmation));
                });
        },
        toggleUpdates() {
            this.openUpdates = !this.openUpdates;
            if(this.openUpdates)
                $("#updates").show(400);
            else
                $("#updates").hide(400);
        },
        changeAmount(amount = 1) {
            this.updateAmount = parseInt(amount);
        },
        async updateDisability() {
            if(!this.disability)
                return;

            if(!this.update || this.update === '') {
                const toast = useToast();
                toast.error("Update nog niet ingevuld", {
                    icon: "fa fa-times"
                });
                $("#updateIcon").addClass('fa-save').removeClass('fa-spinner fa-spin');
                $("#updateButton").removeAttr("disabled");
                $("#updateButton").addClass('btn-primary').removeClass('btn-light');
                return;
            }

            if(this.updateAmount < -1) {
                const toast = useToast();
                toast.error("Kies hoe je je voelt ten opzichte van de vorige update", {
                    icon: "fa fa-times"
                });
                $("#updateIcon").addClass('fa-save').removeClass('fa-spinner fa-spin');
                $("#updateButton").removeAttr("disabled");
                $("#updateButton").addClass('btn-primary').removeClass('btn-light');
                return;
            }

            this.modals.push(
                this.$createModal("Update versturen", "Weet je zeker dat je deze update wilt versturen?", "fa-check", "Ja", "/disability", this.$ModalType.Question, "sendUpdate"));
        },
        async sendUpdate() {
            this.closeModal();

            $("#updateIcon").addClass('fa-spinner fa-spin').removeClass('fa-save');
            $("#updateButton").attr("disabled", "disabled");
            $("#updateButton").addClass('btn-light').removeClass('btn-primary');

            await this.$http.post(`/disabilities/${this.disability.id}/add_update`, {
                id: this.disability.id,
                description: this.update,
                development: this.updateAmount,
                disability: this.disability
            }).then(() => {
                this.modals.push(
                    this.$createModal("Update verstuurd", "Bedankt voor je update. Hij is succesvol bij ons binnengekomen.", "fa-check", "OK!", "/disability", this.$ModalType.Confirmation));
                    $("#updateIcon").addClass('fa-check').removeClass('fa-spinner fa-spin');
                    $("#updateButton").removeAttr("disabled");
                    $("#updateButton").addClass('btn-success').removeClass('btn-light');
            }).catch((err) => {
                console.log(err);
                $("#updateIcon").addClass('fa-save').removeClass('fa-spinner fa-spin');
                $("#updateButton").removeAttr("disabled");
                $("#updateButton").addClass('btn-primary').removeClass('btn-light');
                const toast = useToast();
                toast.error("Je update kon niet verstuurd worden. Probeer het later nog eens.", {
                    icon: "fa fa-times"
                });
            });
        }
    },
    mounted() {
        // Haal alle ziekmeldingen op van de 
        this.getDisabilites();

        // TODO enkel de disability ophalen, obv het id in de url. hiervoor moet ook een api call bij komen

        // Verberg melding
        $("#reminder").hide();
        $("#updates").hide();

        $(".disability").hide();
        $(".disability").show(400);

        $("#doctor-updates").hide();
    }
}
</script>

<style scoped>
h6 {
    font-weight: 800;
}

.bg-worse {
    background-color: rgba(255, 0, 0, 0.2) !important;
}

.btn {
    transition: opacity var(--transition-400ms) ease;
}

.icon {
    width: 24px;
    height: 24px;
    position: relative;
    opacity: 1;
}

.invert {
    filter: invert();
}

.flip {
    transform: scaleY(-1);
}

.update-type {
    transition: border var(--transition-400ms) ease;
}

.btn-secondary {
    background-color: #63d970 !important;
    color: #003b1b;
    position: relative;
    border: 0;
}
.btn-secondary:hover{
    background-color: #83d98d !important;
}
.btn-secondary:active {
    color: #003b1b;
}

.btn-success {
    background-color: #02ff81 !important;
    color: #333;
}
.btn-success:hover{
    background-color: #68ffb4 !important;
    color: #333;
}

.bg-neutral {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-better {
    background-color: rgba(0, 255, 64, 0.2) !important;
}

.fa {
    transition: transform var(--transition-400ms);
}

.rot-180 {
    transform: rotateZ(180deg);
}

.status {
    cursor: pointer;
}

.input-group-text {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.bg-green {
    background-color: var(--light-green);
}

.bg-yellow {
    background-color: var(--light-yellow);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.dashed {
    border-style: dashed !important;
}

.update {
    color: var(--light-blue);
}

td {
    cursor: pointer;
}

table tr td,
table tr,
th {
    background-color: transparent !important;
}

.border-transparent {
    border: 2px solid transparent;
}

.border-selected {
    border: 2px solid #fff;
}

.add {
    position: absolute;
    left: 6px;
    top: 6px;
    font-size: 20px;
    font-weight: 100;
}

/* The slider itself */
.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 2px;
    /* Specified height */
    background: #d3d3d3;
    /* Grey background */
    outline: none;
    /* Remove outline */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    padding: 0;
    cursor: pointer;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: var(--dark-blue);
    cursor: pointer;
    border-radius: 50%;
    transition: background var(--transition-400ms) ease-in-out;
}

.text-brighter {
    filter: brightness(150%);
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    border-radius: 50%;
    cursor: pointer;
}

.h3-product {
    font-weight: 800;
    font-size: 18px;
}

.h4-product {
    font-weight: bold;
    font-size: 16px;
    color: #00B1C5;
}

.h5-product {
    font-weight: bold;
    font-size: 18px;
}

.smaller {
    font-size: 9px;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.middle {
    position: absolute;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
}

.slider-red::-webkit-slider-thumb {
    background: #ff3355 !important;
}

.slider-blue::-webkit-slider-thumb {
    background: var(--light-blue) !important;
}

.slider-green::-webkit-slider-thumb {
    background: #10f0a5 !important;
}
</style>