<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal">Overstappen</h1>

            <div class="bg-light text-dark rounded-2 small position-relative">
                <div class="p-3 pb-0"><i class="fa fa-info-circle me-1"></i>
                Bij Zelfstandig Ondernemers worden alle onderdelen van je productsamenstelling vanuit je maandelijkse inleg betaald.
                </div>
                <div class="p-3 pb-0">
                    Zowel je maandelijkse administratiekosten als de maandelijkse premie van je ongevallenverzekering worden niet meer apart gefactureerd, maar geïncasseerd vanuit je inleg.
                </div>
                <div class="p-3 pb-0">
                    Ook kun je bij Zelfstandig Ondernemers kiezen voor twee hogere schenkingsniveaus.
                </div>
                <div class="p-3">
                    Kijk voor meer informatie op onze website <a href="https://www.zelfstandigondernemers.nl">zelfstandigondernemers.nl</a>
                </div>
            </div>

            <div class="col mt-2">
                <span @click="confirmChange()" class="btn btn-primary btn-bg-light-blue"><i class="fas fa-sync-alt"></i> Ja ik wil overstappen</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>

<script type="text/javascript">
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { setCustomer, getCustomer } from '@/App.vue';

export default {
    name: 'product-page',
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/products', 'Mijn producten'), new Breadcrumb('/products/to-zo', 'Overstappen')],
            customer: {},
        }
    },
    methods: {
        confirmChange() {
         

            this.$http.post('/customer/to_zo').
                then((response) => {
                    if (response) {
                        setCustomer(response.data);
                    }
                    this.$router.push('/products/change');
                });
        }
    },
    async mounted() {
        this.customer = getCustomer();

    }
}
</script>
