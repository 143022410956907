<template>
  <div class="w-100 m-0 d-flex justify-content-center">
    <div class="container-inputs p-3">
      <h4 class="display-5 text-white">Login</h4>
      <p class="text-white">Open de Google Authenticator app op uw mobile device en voer de validatiecode in onderstaand veld in.</p>
      <form class="space-y-4" @submit.prevent="submit">
        <div>
          <input type="text" id="code" name="code" v-model="code" ref="code"
            class="w-100 px-2 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            autocomplete="off" placeholder="Code" />
        </div>
        <div>
          <button type="submit"
            class="w-100 px-2 py-2 tracking-wide text-white btn btn-primary mt-3 transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
            Confirm
          </button>
        </div>
      </form>

      <div class="text-center mt-2">
        <RouterLink to="/2fa/sms" class="font-medium text-white hover:underline">Sms</RouterLink>
      </div>

      <div class="text-center">
        <RouterLink to="/2fa/yubikey" class="font-medium text-white hover:underline" v-if="setupYubikey">Yubikey</RouterLink>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { getCustomerStorage, getCustomer, setCustomer } from '@/App.vue';
import $ from "jquery";

export default {
  name: 'AuthenticatorPage',
  data() {
    return {
      code: '',
      setupYubikey: false
    }
  },
  methods: {
    async submit() {
      try {
        const response = await this.$http.post('/2fa/authenticator', {
          code: this.code,
        });
        
        getCustomerStorage().setItem('token', response.data.access_token);
        setCustomer(response.data.customer);

        this.$http.get('/dashboard')
          .then((response) => {
              // Deze functie verzorgt de opmaak van het hamburgermenu met een bolletje en het aantal notificiaties voor de gebruiker
              this.$hasActions(response.data);
          })
          .catch((error) => {
              console.log(error);
          });
          
        $(".hamburger-authentication").show();

        this.emitter.emit('login', true);

        await this.$router.push({ path: '/' });
      } catch (error) {
        // this.myParticipants = [];

        console.error(error.response?.data?.errors)
        console.error(error)

        this.code = ''

        this.$refs.code.focus()
      }
    }
  },
  async mounted() {
    this.$refs.code.focus();

    let customer = getCustomer();
    if(customer) {
      this.setupYubikey = customer.yubikey_is_set_up;
    }
  }
};
</script>
