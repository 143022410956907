<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h4 class="display-4 text-white fw-normal">Berichten</h4>
            <hr/>

            <div class="row">
                <div class="col">
                    <a class="btn btn-light mb-2" v-if="pushAvailableOnDevice && !receivePushMessages" @click="initSW"><i class="initPushIcon fa fa-envelope me-1"></i> Pushmelding ontvangen</a>
                    <a class="btn btn-light mb-2" v-if="pushAvailableOnDevice && receivePushMessages" @click="removeSW"><i class="stopPushIcon fa fa-stop me-1"></i> Stop pushmelding</a>
                </div>
            </div>

            <div class="wait-icon-container" v-if="loadingMessages">
                <svg width="300" height="300" class="wait-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path class="spinner_l9ve" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                        transform="translate(12, 12) scale(0)" />
                    <path class="spinner_l9ve spinner_cMYp"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                        transform="translate(12, 12) scale(0)" />
                    <path class="spinner_l9ve spinner_gHR3"
                        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
                        transform="translate(12, 12) scale(0)" />
                </svg>
            </div>

            <div class="row row-cols-1 position-relative">
                <div class="col w-100" id="nomessages">
                    <p class="bg-info rounded-2 p-3 text-dark small"><i class="fa fa-info-circle me-1"></i> Je hebt nog geen berichten.</p>
                </div>
                <div class="col" v-for="(message, index) in messages" :key="message.date_sent">
                    <div
                        v-if="index === 0 || $formatDate(message.date) !== $formatDate(messages[index - 1].date)">
                        <div class="w-100 justify-content-center text-center my-2">
                            <p class="small m-0 p-1 px-2 rounded-2 d-inline-block text-white bg-dark-blue fw-bold">{{ $formatDate(message.date) }}</p>
                        </div>
                    </div>

                    <div
                        :class="[message.sent_at === null ? 'balloon-sent' : (message.is_new ? 'balloon-received is-new' : 'balloon-received'), 'width bg-info text-dark p-3 d-inline-block mb-3']">
                        <p class="m-0 fw-bold" v-if="message.sent_at === null">U</p>
                        <p class="m-0 fw-bold" v-else>Zelfstandig Ondernemers</p>

                        <messageRenderer tag="p" :text="message.message" class-name="mb-0" />

                        <div class="col">
                            <router-link :to="button.url" class="btn btn-primary mt-2 me-2 btn-down" v-for="button in message.buttons" :key="button">{{ button.content ?? '' }}</router-link>
                        </div>
                        <p class="small m-0 fw-bold float-end">
                            {{ formatTime(message.date) }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="row row-cols-1 position-relative" ref="messageInput">
                <div class="col">
                    <textarea class="form-control" rows="4" cols="4" placeholder="Nieuw bericht" id="chatbox"></textarea>
                </div>
                <div class="col">
                    <div class="btn btn-primary mt-2" @click="sendMessage"><i class="fa fa-envelope me-1"></i> Verzenden
                    </div>
                </div>
            </div>

            <div class="position-fixed fx" id="toBottom" @click="scrollToBottom">
                <div class="fixed-size rounded-circle border border-light">
                    <i class="fa fa-arrow-down down text-light"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bg-green {
    background-color: var(--light-green);
}

hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.notif-new {
    position: absolute;
    left: -5px;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    background-color: var(--warning);
}

.width {
    width: 95%;
}

table tr td,
table tr,
th {
    background-color: transparent !important;
    color: #fff;
}

.balloon-received {
    border-radius: 14px;
    float: left;
    overflow: hidden;
}
.is-new {
    animation: blinker 1s linear 3;
}
@keyframes blinker {
    50% {
        opacity: 0.6;
    }
}

.balloon-sent {
    border-radius: 14px;
    background-color: #8ef4ff !important;
    float: right;
    overflow: hidden;
}


.smaller-icon {
    font-size: 20px !important;
    transform: translateY(-10px) !important;
}

.block-product {
    cursor: pointer;
}

.inleg {
    background-color: #fff;
    text-align: center;
    font-weight: 800;
    transition: color var(--transition-400ms) ease, box-shadow var(--transition-200ms) ease, background-color var(--transition-400ms) ease;
    cursor: pointer;
}

.inleg:hover {
    box-shadow: 2px 3px 20px rgba(255, 255, 255, 0.3);
}

.inleg-chosen {
    background-color: #00B1C5;
    color: var(--dark-blue)
}

.btn-bg-light-blue {
    border-color: #00B1C5 !important;
    background-color: #00B1C5 !important;
}

.fixed-size {
    width: 44px;
    height: 44px;
    /* padding-left: 1.5em; */
    border-width: 3px !important;
    position: relative;
    box-shadow: 0 0 3px black;
    background-color: rgba(0, 0, 0, 0.35);

    transition: background-color var(--transition-200ms) ease-in-out;
}

.fixed-size:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.fx {
    bottom: 16px;
    right: 16px;
    z-index: 2000;
    border-radius: 50%;
    cursor: pointer;
}

.btn-bg-light-blue:hover {
    border-color: #62b8c2 !important;
    background-color: #62b8c2 !important;
}

.add {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 24px;
}

.subtract {
    position: absolute;
    left: 12px;
    top: 50%;
    bottom: 50%;
    font-size: 40px;
    font-weight: 100;
    transform: translateY(-20px);
    cursor: pointer;
    transition: opacity var(--transition-200ms) ease;
}

.add:hover {
    opacity: 0.7;
}

.subtract:hover {
    opacity: 0.7;
}

.icon-products {
    width: 25%;
}

.h3-product {
    font-weight: 800;
    font-size: 24px;
}

.h4-product {
    font-weight: bold;
    font-size: 24px;
    color: #00B1C5;
}

.h5-product {
    font-weight: bold;
    font-size: 24px;
}

.small {
    font-size: 14px;
}

.smaller {
    font-size: 11px;
}

.h6-product {
    font-weight: 800;
    font-size: 15px;
}

.wait-icon {
    color: #fff !important;
    position: absolute;
    z-index: 999;
    filter: invert();
    left: calc(50% - 150px);
    top: calc(50% - 150px);
}

.wait-icon-container {
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}

.spinner_l9ve {
    animation: spinner_rcyq 1.2s cubic-bezier(0.52, .6, .25, .99) infinite
}

.spinner_cMYp {
    animation-delay: .4s
}

.spinner_gHR3 {
    animation-delay: .8s
}

@keyframes spinner_rcyq {
    0% {
        transform: translate(12px, 12px) scale(0);
        opacity: 1
    }

    100% {
        transform: translate(0, 0) scale(1);
        opacity: 0
    }
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

td {
    cursor: pointer;
}

.down {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 12px;
    top: 12px;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        max-width: 0;
    }

    100% {
        opacity: 1;
        max-width: 100%;
    }
}
</style>

<script type="text/javascript">
/*eslint-disable*/
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import { getCustomerStorage } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { useToast } from "vue-toastification";
import messageRenderer from '@/messageRenderer'

class Message {
    constructor(date, message, buttons = []) {
        this.date = date;
        this.message = message;
        this.buttons = buttons;
        this.created_at = date;
    }
}

//import axios from 'axios';
export default {
    name: "messages-view",
    components: {
        Breadcrumbs,
        messageRenderer,
    },
    data() {
        return {
            messages: [],
            charLimit: 55,
            lastDate: new Date(),
            breadcrumbs: [new Breadcrumb("/messages", "Berichten")],
            icons: ['fa-check', 'fa-check-double'],
            loadingMessages: true,
            
            receivePushMessages: false,

            loadingMessagePage: 1,
            loadedMessagePage: 0,
            loadedMax: false,

            maxMessageId: 0,
        }
    },
    methods: {
        fetchMessages() {
            if(!this.loadedMax) {
                this.$http.get('/messages?page=' + this.loadingMessagePage)
                    .then(response => {
                        const messageInput = this.$refs.messageInput;
                        let previousOffset = messageInput?.offsetTop ?? 0

                        this.loadedMessagePage = response.data.current_page;


                        if(this.loadedMessagePage == 1) {
                            this.messages = response.data.data.reverse();// Laatste bericht onderaan, bij terug scrollen bijladen
                        } else {
                            this.messages.unshift(...response.data.data.reverse());
                        }
                        this.loadingMessages = false;

                        for(let i = 0; i < this.messages.length; i++) {
                            if(!this.messages[i] || !this.messages[i].message)
                                continue;
                            this.messages[i].message = this.messages[i].message.replaceAll("\n", "\n ");
                        }                        
                        
                        if(response.data.data.length === 0) {
                            if(this.loadedMessagePage == 1) {
                                $("#nomessages").show('slow');
                            } else {
                                $("#nomessages").hide('slow');
                            }
                            this.loadedMax = true;
                        } else {

                            this.messages.forEach(function(message) {
                                if(this.maxMessageId < message.id) {
                                    this.maxMessageId = message.id;
                                }
                            }.bind(this))

                            this.$nextTick(function() {
                                const messageInput = this.$refs.messageInput;
                                
                                if(this.loadedMessagePage == 1) {
                                    const messageReceived = $('.balloon-received.is-new').first();
                                    if(messageReceived) {
                                        if(messageReceived.parent().prev() && messageReceived.parent().prev()[0]) {
                                            messageReceived.parent().prev()[0].scrollIntoView({
                                                behavior: 'instant'
                                            })
                                        }
                                    } else {
                                        messageInput.scrollIntoView({
                                            behavior: 'instant'
                                        })
                                    }
                                } else {
                                    window.scrollTo({
                                        top: messageInput.offsetTop - previousOffset,
                                        left: 0,
                                        behavior: 'instant',
                                    });
                                }

                                if(window.scrollY == 0) {
                                    if(this.loadingMessagePage == this.loadedMessagePage) {
                                        this.loadingMessagePage++;

                                        // volgende laden
                                        this.fetchMessages();
                                    }
                                }
                            }.bind(this));
                        }
                    })
            }
        },

        scrollToBottom() {
            const messageInput = this.$refs.messageInput;
            messageInput.scrollIntoView({behavior: 'smooth'})
        },

        handleScroll() {
            // Any code to be executed when the window is scrolled
            if(window.scrollY == 0) {
                if(this.loadingMessagePage == this.loadedMessagePage) {
                    this.loadingMessagePage++;

                    // volgende laden
                    this.fetchMessages();
                }
            }
        },

        validateNewMessages() {
            if(this.loadingMessagePage == this.loadedMessagePage) {
                this.$http.post('/messages/has_new', {
                    message_id: this.maxMessageId
                }).then(function(response) {
                    if(response.data.has_new) {
                        this.loadingMessagePage = 1;
                        this.loadedMessagePage = 0;
                        this.loadedMax = false;
                        
                        this.fetchMessages();
                    }
                }.bind(this))
            }
        },

        async sendMessage() {
            let message = $("#chatbox").val();

            if(message && message != '') {
                let msg = new Message(new Date(), message);
                $("#chatbox").val("");

                try {
                    await this.$http.post('/messages/send', msg, {
                        config: {
                            showMessageToast: false,
                        }
                    });

                    this.loadingMessagePage = 1;
                    this.loadedMessagePage = 0;
                    this.loadedMax = false;

                    this.fetchMessages();
                } catch (error) {
                    console.error('Error sending message:', error);
                }
            }
        },

        formatTime(dateTime) {
            const options = { hour: 'numeric', minute: 'numeric' };
            return new Date(dateTime).toLocaleTimeString(undefined, options);
        },

        async removeSW() {
            $('.stopPushIcon').removeClass("fa-stop").addClass("fa-spinner").addClass("fa-spin");
            $('.initPushIcon').removeClass("fa-spinner").removeClass("fa-spin").addClass("fa-envelope");

            navigator.serviceWorker.getRegistrations().then(registrations => {
                registrations.forEach(registration => {
                    if(registration.active.scriptURL == window.location.protocol + '//' + window.location.hostname + '/sw.js') {
                        registration.unregister();
                        this.receivePushMessages = false;
                    }
                });
            }).catch((err) => {
                console.warn(err);
            });

        },
        
        async initSW() {
            // Als er geen serviceworker wordt ondersteund
            if(!('serviceWorker' in navigator) || !('PushManager' in window) || localStorage.getItem('token') === null) {
                return;
            }

            $('.initPushIcon').removeClass("fa-envelope").addClass("fa-spinner").addClass("fa-spin");
            $('.stopPushIcon').removeClass("fa-spinner").removeClass("fa-spin").addClass("fa-stop");

            await navigator.serviceWorker.register('/sw.js')
                .then(() => {
                    this.initPush();
                })
                .catch((err) => {
                    console.warn(err);
                });
        },

        initPush() {
            if(!navigator.serviceWorker.ready) {
                console.error('!navigator.serviceWorker.ready');
                return;
            }

            new Promise(function(resolve, reject) {
                const PERMISSION_RESULT = Notification.requestPermission(function(result) {
                    resolve(result);
                });

                
                if(PERMISSION_RESULT)
                    PERMISSION_RESULT.then(resolve, reject);
            })
            .then((permissionResult) => {
                if(permissionResult !== 'granted') {
                    // throw new Error('Geen toestemming gegeven');

                    const toast = useToast();
                    toast.error("Geen toestemming gegeven", {
                        icon: "fa fa-times"
                    });
                    this.receivePushMessages = false;
                }
                this.subscribeUser();
            }).catch((err) => {
                console.warn(err);
            });
        },

        subscribeUser() {
            navigator.serviceWorker.ready
                .then((registration) => {
                    function urlBase64ToUint8Array(base64String) {
                        const PADDING = '='.repeat((4 - base64String.length % 4) % 4);
                        const BASE64 = (base64String + PADDING)
                            .replace(/-/g, '+')
                            .replace(/_/g, '/');

                        const RAW_DATA = window.atob(BASE64);
                        const OUTPUT_ARRAY = new Uint8Array(RAW_DATA.length);

                        for(let i = 0; i < RAW_DATA.length; i++) {
                            OUTPUT_ARRAY[i] = RAW_DATA.charCodeAt(i);
                        }

                        return OUTPUT_ARRAY;
                    }

                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_VAPID_PUBLIC_KEY)
                    });
                })
                .then((pushSubscription) => {
                    this.storePushSubscription(pushSubscription);
                }).catch((err) => {
                    console.warn(err);
                });
        },

        /**
         * Omzetten van een base64 url naar een array met integers
         * @param string base64String 
         */
        

        /**
         * Opslaan van een push subscription (toestemming van user) in de database
         * @param PushSubscription pushSubscription 
         */
        storePushSubscription(pushSubscription) {
            this.$http.post('/push_subscription', pushSubscription).then((response) => {
                // butten voor aanmelden push verbergen
                this.receivePushMessages = true;
                return response;
            }).catch((err) => {
                console.warn(err);
            });
        },
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
        clearInterval(this.validateNewMessagesInterval);
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.validateNewMessagesInterval = setInterval(this.validateNewMessages, 5000)

        this.pushAvailableOnDevice = localStorage.getItem('customerStorage') == 'localStorage' && ('serviceWorker' in navigator) && ('PushManager' in window);

        // this.initSW();

        if(Notification.permission === 'granted') {
            navigator.serviceWorker.getRegistrations().then(registrations => {
                registrations.forEach(registration => {
                    if(registration.active.scriptURL == window.location.protocol + '//' + window.location.hostname + '/sw.js') {
                        this.receivePushMessages = true;
                    }
                });
            });
        }

        $("#nomessages").hide();
    },
    created() {
        this.fetchMessages();
    },
}
</script>