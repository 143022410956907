<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="display-4 fw-normal text-white">Openstaande inleg</h1>

            <div id="openDirectDebit" class="w-100">
                <p class="p-3 rounded-2 bg-warning text-dark small text-start"><i class="fa fa-exclamation me-1"></i> Bij de
                    controle van onze
                    administratie is gebleken dat de automatische incasso voor jouw inleg aan het vangnet voor één of meerdere
                    maanden niet is gelukt.</p>

                <div class="p-3 py-1 rounded-2 bg-transparent text-light small text-start position-relative">
                    <ul class="px-3">
                        <li class="my-3">Wij proberen binnen enkele dagen opnieuw een automatische incasso uit te voeren.</li>
                        <li class="my-3">Je kunt de inleg ook zelf overmaken op <mark>NL 23 RABO 0337 0036 37</mark> ten name
                            van <b>Stichting
                                Beheer Derdengelden Voorzieningenvangnet</b>.</li>
                        <li class="my-3">Let op, met een betalingsachterstand kun je geen gebruik maken van de dienstverlening
                            van het vangnet en
                            kun je je dus ook niet ziek melden.</li>
                    </ul>
                </div>

                <hr class="mt-4" />

                <h3 class="fw-thin text-white mt-3">Betaal openstaande inleg direct</h3>
            </div>

            <div class="row">
                <div class="col openDirectDebits">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>
                                    <div class="form-check">
                                        <input class="form-check-input indeterminate" type="checkbox" value="" id="selectAll" @click="selectAll" checked>
                                    </div>
                                </th>
                                <th>Periode</th>
                                <th class="text-end">Bedrag</th>
                                <!-- <th>Beschrijving</th> -->
                                <th>Incasso mislukt</th>
                                <th>iDeal status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(openDirectDebit, index) in openDirectDebits" :key="index">
                                <template v-if="openDirectDebit.last_mollie_payment?.status!='paid'">
                                    <td>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" @input="updateChecked"
                                                :id="'checkbox' + index" checked>
                                        </div>
                                    </td>
                                    <td @click="() => toggleInvoice(index)">
                                        {{ $formatDate(openDirectDebit.period_start_at) }} - {{ $formatDate(openDirectDebit.period_end_at) }}
                                    </td>
                                    <td class="text-end" @click="() => toggleInvoice(index)">&euro;{{ openDirectDebit.amount.toFixed(2) }}</td>
                                    <td @click="() => toggleInvoice(index)">
                                        {{ $formatDate(openDirectDebit.failed_at) }}<br />
                                        {{ openDirectDebit.last_assigned_banktransaction.reason_return }}
                                    </td>
                                    <td @click="() => toggleInvoice(index)">
                                        {{ openDirectDebit.last_mollie_payment ? mollie_status_names[openDirectDebit.last_mollie_payment.status] : '' }}
                                    </td>
                                </template>
                                <template v-else>
                                    <td>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" disabled>
                                        </div>
                                    </td>
                                    <td>
                                        {{ $formatDate(openDirectDebit.period_start_at) }} - {{ $formatDate(openDirectDebit.period_end_at) }}
                                    </td>
                                    <td class="text-end">&euro;{{ openDirectDebit.amount.toFixed(2) }}</td>
                                    <td>
                                        {{ $formatDate(openDirectDebit.failed_at) }}<br />
                                        {{ openDirectDebit.last_assigned_banktransaction.reason_return }}
                                    </td>
                                    <td>
                                        Betaald
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>

                    <div class="row row-cols-1 row-cols-md-2">
                        <div class="col">
                            <p class="text-light float-start text-start mt-3">
                                {{ numberOfChecked || 0 }} geselecteerd
                            </p>
                        </div>
                        <div class="col">
                            <button :class="['btn m-1 float-start float-md-end', numberOfChecked === 0 ? 'btn-secondary' : 'btn-primary']" :disabled="hasChecked" @click="initiatePayment"><img src="../assets/ideal.svg" class="ideal"> Betaal direct online</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Wanneer geen openstaande inleg -->
            <div id="noOpenDirectDebits">
                <p class="p-3 bg-info text-dark text-start rounded-2"><i class="fa fa-check-circle me-1"></i> Je hebt momenteel geen openstaande inleg bij ons! Mooi zo!</p>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import Breadcrumbs from '../components/BreadcrumbsComponent.vue';
import { Breadcrumb } from '../App.vue';

export default {
    name: 'open-directdebits',
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            openDirectDebits: [],
            numberOfChecked: 0,
            mollie_status_names: {
                'open': 'Aangemaakt',
                'pending': 'In behandeling',
                'paid': 'Betaald',
                'authorized': 'Geautoriseerd',
                'expired': 'Verlopen',
                'failed': 'Mislukt',
                'canceled': 'Geannuleerd',
            },
            breadcrumbs: [new Breadcrumb("/invoices", "Administratieoverzicht",), new Breadcrumb("/open-directdebits", "Openstaande inleg")],
        }
    },
    methods: {
        selectAll() {
            if($(`#selectAll`).prop('checked')) {
                for (let i = 0; i < this.openDirectDebits.length; i++) {
                    $(`#checkbox${i}`).prop('checked', true);
                }
            } else {
                for (let i = 0; i < this.openDirectDebits.length; i++) {
                    $(`#checkbox${i}`).prop('checked', false);
                }
            }
            this.updateChecked();
        },
        updateChecked() {
            let checked = 0;
            for (let i = 0; i < this.openDirectDebits.length; i++) {
                checked += $(`#checkbox${i}`).is(':checked');
            }

            if(checked === 0)
                $("#selectAll").prop("checked", false);

            if(checked === this.openDirectDebits.length)
                $("#selectAll").prop("checked", true);

            this.numberOfChecked = checked;
        },
        toggleInvoice(index) {
            let checked = $(`#checkbox${index}`).prop("checked");
            $(`#checkbox${index}`).prop("checked", !checked);
            this.updateChecked();
        },
        async fetchMyOpenDirectdebits() {
            try {
                const params = new URL(location.href).searchParams;
                let open_directdebits_url = 'open_directdebits';
                if(params.get('check') && params.get('check') != '') {
                    open_directdebits_url ='open_directdebits?check=' + params.get('check')

                    // this.$router.replace('/open-directdebits')
                }
                const open_directdebits = await this.$http.get(open_directdebits_url)
                if(!open_directdebits)
                    return;

                let numberOfChecked = 0
                open_directdebits.data.forEach(function(open_directdebit) {
                    if(open_directdebit.last_mollie_payment?.status != 'paid') {
                        numberOfChecked++
                    }
                })
                this.numberOfChecked = numberOfChecked
                this.openDirectDebits = open_directdebits.data;

                if(this.openDirectDebits.length > 0) {
                    $("#openDirectDebit").show(400);
                    $(".openDirectDebits").show(400);
                } else {
                    $("#noOpenDirectDebits").show(400);
                }
            } catch (error) {
                console.error(error.response?.data?.errors)
                console.error(error)
            }
        },
        async initiatePayment() {
            try {
                let checkedopenDirectDebits = [];
                for(let i = 0; i < this.openDirectDebits.length; i++) {
                    const isChecked = $(`#checkbox${i}`).is(":checked");
                    console.log(`isChecked (${i}): `, isChecked, this.openDirectDebits[i]);
                    if(isChecked) {
                        checkedopenDirectDebits.push(this.openDirectDebits[i].id);
                    }
                }

                await this.$http.post('open_directdebits', {
                    directdebit: checkedopenDirectDebits
                }).
                then((response) => {
                    location.href = response.data.checkout_url;
                }).
                catch((err) => {
                    console.log(err);
                });
            } catch (error) {
                // this.myParticipants = [];

                console.error(error.response?.data?.errors)
                console.error(error)

                this.code = ''
            }
        }
    },
    computed: {
        hasChecked() {
            return this.numberOfChecked === 0;
        }
    },
    mounted() {
        $(".allInvoices").hide();
        document.title = "Zelfstandig Ondernemers - Openstaande inleg";
        this.numberOfChecked = this.openDirectDebits.length;

        $(".openDirectDebits").hide();
        $("#openDirectDebit").hide();
        $("#noOpenDirectDebits").hide();

        this.fetchMyOpenDirectdebits();
    }
}
</script>

<style scoped>
h6 {
    font-weight: 800;
}

table tr td,
table tr,
th {
    background-color: transparent !important;
    color: #fff;
    position: relative;
}

tr {
    cursor: pointer;
}

input[type="radio"] {
    transform: scale(1.5);
}

p {
    text-align: right;
}

mark {
    border-radius: 3px;
    background-color: orange;
}

.tl {
    text-align: left !important;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
    text-align: left;
}

.bg-transparent {
    background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>