<template>
  <div class="w-100 m-0 d-flex justify-content-center position-relative">
    <div class="container-inputs p-3">
      <h4 class="display-5 text-white">Wachtwoord vergeten</h4>
      <form class="space-y-4" @submit.prevent="submit">
        <div>
          <label for="email" class="text-sm text-white">Email</label>
          <input type="email" id="email" name="email" v-model="email" ref="email"
            class="w-100 mb-3 px-2 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" />
        </div>
        <div>
          <button type="submit"
            class="w-100 px-2 py-2 tracking-wide btn btn-primary text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
            Wachtwoord herstellen
          </button>
        </div>
      </form>
      <div class="text-center text-white pt-3">
        Nog geen account? Vraag ook dan via bovenstaand formulier een nieuw wachtwoord aan met het e&#8209;mailadres waarmee u zich geregistreerd heeft.
      </div>
      <div class="text-center text-white pt-3">
        <RouterLink to="/loing" class="font-medium text-white hover:underline">Terug naar login</RouterLink>
      </div>
    </div>
  </div>
</template>

<style>
a {
  text-decoration: underline;
}
</style>

<script>
export default {
  name: 'ForgotPasswordPage',
  data() {
    return {
      email: '',
    }
  },
  methods: {
    async submit() {
      try {
        await this.$http.post('/password/email', {
          email: this.email,
        });

        // TODO, melding dat je een mail krijgt

        await this.$router.push({ path: '/login' });
      } catch (error) {
        // this.myParticipants = [];

        console.error(error.response?.data?.errors)
        console.error(error)

        this.$refs.email.focus()
      }
    }
  },
  mounted() {
    // TODO, lijkt nog niet te werken:
    this.$refs.email.focus()
  }
};
</script>