<template>
  <div class="w-100 m-0 d-flex justify-content-center">
    <div class="container-inputs p-3">
      <h1 class="display-4 text-white fw-normal">Wachtwoord vergeten</h1>
      <form class="space-y-4" @submit.prevent="submit">
        <div>
          <label for="email" class="text-sm text-white">Email</label>
          <input type="email" id="email" name="email" v-model="email" ref="email" readonly disabled
            class="w-100 mb-3 px-2 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40" />
        </div>
        

        <div>
          <div>
            <label for="password" class="text-sm text-white">Nieuw wachtwoord</label>
          </div>
          <input type="password" id="password" name="password" v-model="password" ref="password"
            class="w-100 px-2 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            autocomplete="new-password" />
        </div>

        <div>
          <div>
            <label for="password_confirmation" class="text-sm text-white">Nieuw wachtwoord bevestigen</label>
          </div>
          <input type="password" id="password_confirmation" name="password_confirmation" v-model="password_confirmation"
            class="w-100 px-2 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            autocomplete="new-password" />
        </div>

        <div>
          <button type="submit"
            class="w-100 px-2 py-2 my-3 tracking-wide btn btn-primary text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600">
            Nieuw wachtwoord toepassen
          </button>
        </div>
      </form>
      
      <p class="mt-8 text-xs font-light text-center text-gray-700">
        <router-link class="mt-8 text-xs font-light text-center text-white" to="/forgot-password">
          Wachtwoord vergeten?
        </router-link>
      </p>
    </div>
  </div>
</template>

<style>
a {
  text-decoration: underline;
}
</style>

<script type="text/javascript">
/*eslint-disable*/
import { useToast } from 'vue-toastification';

export default {
  name: 'ResetPasswordPage',
  data() {
    return {
      email: '',
      invalid: false,
      reset_token: '',
      password: '',
      password_confirmation: '',
    }
  },
  methods: {
    async submit() {
      try {
        await this.$http.post('/password/reset', {
          token: this.reset_token,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });
        
        await this.$router.push({ path: '/login' });
      } catch (error) {
        // this.myParticipants = [];
        const toast = useToast();
        if(error.response.data.errors !== "")
          toast.error(error.response.data.errors);

        console.error(error.response?.data?.errors)
        console.error(error)

        this.$refs.password.focus()
      }
    }
  },
  async mounted() {
    const params = new URL(location.href).searchParams;

    try {
      this.reset_token = params.get('token');

      const response = await this.$http.get('/password/reset?d=' + params.get('d'))

      this.email = response.data.email;
      this.invalid = response.data.invalid;

      this.$refs.password.focus()

    } catch (error) {
      console.error(error)
      
    }

  }
};
</script>