<template>
    <div class="w-100 m-0 d-flex justify-content-center min-full-page">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h3 class="display-4 text-white fw-normal">Producten wijzigen</h3>
            <hr/>

            <p v-if="dynamic.disabled_now" class="bg-warning p-3 rounded-2 small text-dark">
                <i class="fa fa-exclamation me-1"></i> Omdat je op dit moment arbeidsongeschikt gemeld bent is wisselen voor je AOV of ongevallenverzekering niet toegestaan. De inleg die je kiest geldt voor de resterende wachttijd en vanaf het einde van je schenkingen
            </p>

            <div class="animate-inputs mt-3 w-100">
                <!-- Maandelijkse inleg -->
                <div class="bg-block-alt subtle-border d-flex w-100 position-relative px-3 selection-border" @click="() => toggleStep('payment', ['aov', 'cov', 'retirement', 'schedule'])" role="button">
                    <div class="col w-50 p-0">
                        <h4 class="py-3 m-0 fw-bold text-dark-blue">Maandelijkse inleg</h4>
                    </div>
                    <div class="col w-50 p-0 position-relative">
                        <div class="d-flex float-end m-0 h-100 text-dark">
                            <span class="m-0 small my-auto fade-in float-end" :key="showPaymentSubgroup?.subgroup_payment_monthy">{{ $numberFormatEuro(showPaymentSubgroup?.subgroup_payment_monthy ?? 0, 0) }} p/m</span>
                        </div>
                    </div>
                </div>

                <div class="bg-block subtle-border payment w-100" v-if="payment_subgroups.length">
                    <h6 class="text-dark fw-bold text-center py-3 px-3 pb-1">Kies je inleg</h6>
                    <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-3 position-relative animate-inputs-right">
                        <div class="row row-cols-3">
                            <div class="col-2">
                                <img src="@/assets/svg/min2.svg" class="product-alternation-icon float-start ms-3 start-0 big-btn" @click="() => decreasePayment()" role="button" v-if="showPaymentSubgroupIndex > 0">
                            </div>

                            <div class="col text-center text-dark w-100 pe-none">
                                <h4 class="text-center w-100 m-0 vertical-align text-info fw-bold rounded-1 h4-product">
                                    <span class="p-2 rounded-1 text-white bg-info">{{ $numberFormatEuro(showPaymentSubgroup?.subgroup_payment_monthy, 0) }} p/m</span>
                                </h4>
                                <p :class="['text-center small m-0 mt-5 pt-5 opacity-anim', isPaymentValid ? 'opacity-0' : '']">Kies eventueel een andere invulling van één van de producten om een lagere maandelijkse inleg mogelijk te maken</p>
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>
                            </div>

                            <div class="col-2">
                                <img src="@/assets/svg/plus.svg" class="product-alternation-icon float-end end-0 me-3 big-btn" @click="() => increasePayment()" v-if="showPaymentSubgroupIndex + 1 < payment_subgroups.length" role="button">
                            </div>
                        </div>
                    </div>
                    <div class="btn btn-primary ms-2 mt-0 mb-2" @click="() => toggleStep('aov', [], true)"><i class="fa fa-arrow-down"></i> Volgende stap</div>
                </div>

                <!-- AOV -->
                <div :class="[['aov', 'cov', 'retirement', 'savings', 'schedule'].indexOf(showStep) >= 0 ? 'bg-white' : 'bg-non-selectable', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('aov', ['cov', 'retirement', 'savings', 'schedule'])" role="button">
                    <div class="col w-50 p-0">
                        <h4 class="py-3 m-0 fw-bold text-dark-blue">AOV</h4>
                    </div>
                    <div class="col w-50 p-0 position-relative">
                        <div class="d-flex float-end m-0 h-100 text-dark">
                            <span v-if="!dynamic.disabled_now" :class="['m-0 small my-auto', isAovValid ? '' : 'text-danger']">{{ $numberFormatEuro(showAovSubgroup?.subgroup_payout_monthly ?? 0, 0) }} p/m</span>

                            <span v-if="dynamic.disabled_now" class="m-0 small my-auto">{{ $numberFormatEuro(showAovSubgroup.subgroup_payout_monthly - (showCovSubgroup?.monthly_costs ?? 0 , 0) - show_retirement_after_payment_amount - show_savings_during_payment_amount) }} p/m</span>
                        </div>
                    </div>
                </div>

                <!-- Instellen AOV -->
                <div class="bg-block aov subtle-border w-100" v-if="showAovSubgroup">
                    <h6 class="text-dark fw-bold text-center py-3 pb-1">Kies je arbeidsongeschiktheidsverzekering</h6>
                    <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-0 position-relative animate-inputs-right">
                        <div class="row row-cols-3">
                            <div class="col-2">
                                <img src="@/assets/svg/min2.svg" class="product-alternation-icon float-start ms-3 start-0 big-btn" @click="() => decreaseAOV()" v-if="!dynamic.disabled_now && showAovSubgroupIndex > 0" role="button">
                            </div>

                            <div class="col text-center text-dark w-100 pe-none">
                                <div class="text-center mb-2">
                                    <div class="icon-products-container d-inline-block">
                                        <img class="icon-products" src="@/assets/icon-bandage.svg" alt="Pleister">
                                    </div>
                                </div>
                                <h4 :class="['text-center m-2 mt-3 fw-bold d-inline-block rounded-1 h4-product', isAovValid ? 'text-info' : 'text-danger']">{{ $numberFormatEuro(showAovSubgroup.expected_monthly_costs , 0) }} p/m</h4>
                                <p class="text-center small font-italic m-0">Inclusief vangnetkosten</p>
                                <p class="text-center small font-italic m-0">Inkomen bij ziekte ({{ showAovSubgroup.payout_max_months/12 }} jaar):</p>
                                <h5 class="text-center m-2 fw-bold h5-product">{{ $numberFormatEuro(showAovSubgroup.subgroup_payout_monthly, 0) }}</h5>
                                <p class="text-center small m-0">netto per maand</p>

                                <template v-if="dynamic.disabled_now">
                                    <div class="text-center m-2 aov">
                                        <h5 class="h5-product">{{ $numberFormatEuro(showAovSubgroup.subgroup_payout_monthly - (showCovSubgroup?.monthly_costs ?? 0) - show_retirement_after_payment_amount - show_savings_during_payment_amount - (showAovSubgroup?.monthly_costs ?? 0), 0) }}</h5>
                                        <p class="text-center small m-0 text-start">na aftrek kosten overige producten</p>
                                    </div>
                                </template>

                                <div class="mt-2 mb-0">
                                    <div class="bg-warning text-dark rounded-2 m-0 text-start small w-100 min-aov">
                                        <div class="p-3">
                                            <i class="fa fa-exclamation me-1"></i>Je kunt dit product pas kiezen bij een inleg vanaf {{ $numberFormatEuro(showAovSubgroup.available_from_payment_monthly, 0) }}. Verhoog je maandelijkse inleg of kies een ander product.
                                        </div>
                                    </div>
                                </div>
                            
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>

                            </div>

                            <div class="col-2">
                                <img src="@/assets/svg/plus.svg" class="product-alternation-icon float-end end-0 me-3 big-btn" @click="() => increaseAOV()" v-if="!dynamic.disabled_now && showAovSubgroupIndex + 1 < aov_subgroups.length" role="button">
                            </div>
                        </div>
                    </div>
                    <div :class="['btn d-inline-block ms-2 mt-0 mb-2', isAovValid ? 'btn-primary' : 'btn-warning']" @click="() => toggleStep('cov', [], true)"><i :class="['fa', isAovValid ? 'fa-arrow-down' : 'fa-exclamation-triangle']"></i> Volgende stap</div>
                </div>

                <!-- COV -->
                <!-- Instellen collectieve ongevallenverzekering -->
                <div :class="[['cov', 'retirement', 'savings', 'schedule'].indexOf(showStep) >= 0 ? 'bg-white' : 'bg-non-selectable', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('cov', ['retirement', 'savings', 'schedule'])" role="button">
                    <div class="col w-50 p-0">
                        <h4 class="py-3 m-0 fw-bold text-dark-blue">COV</h4>
                    </div>
                    <div class="col w-50 p-0 position-relative">
                        <div class="d-flex float-end m-0 h-100 text-dark">
                            <span :class="['m-0 small my-auto fade-in', isCovValid ? '' : 'text-danger']" :key="showCovSubgroup?.payout_once">{{ $numberFormatEuro(showCovSubgroup?.payout_once ?? 0, 0) }}</span>
                        </div>
                    </div>
                </div>

                <div class="bg-block subtle-border cov w-100" v-if="dynamic.hasOwnProperty('disabled_now')">
                    <h6 class="text-dark fw-bold text-center py-3 pb-1">Kies je collectieve ongevallenverzekering</h6>
                    <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-3 position-relative animate-inputs-right">
                        <div class="row row-cols-3">
                            <div class="col-2">
                                <img src="@/assets/svg/min2.svg" class="product-alternation-icon float-start ms-3 start-0 big-btn" @click="() => decreaseCOV()" v-if="!dynamic.disabled_now && showCovSubgroupIndex >= 0" role="button">
                            </div>

                            <div class="col text-center text-dark w-100 pe-none">
                                <div class="text-center mb-2">
                                    <div class="icon-products-container d-inline-block">
                                        <img class="icon-products" src="@/assets/icon-brokenleg.svg" alt="Gebroken been">
                                    </div>
                                </div>
                                <h4 :class="['text-center m-2 mt-3 fw-bold d-inline-block rounded-1', isCovValid ? 'text-info' : 'text-danger']">{{ $numberFormatEuro(showCovSubgroup?.monthly_costs ?? 0, 0) }} p/m</h4>
                                <p class="text-center small font-italic m-0">Geen kosten</p>
                                <p class="text-center small font-italic m-0">Uitkering bij ziekte</p>
                                <p class="text-center small font-italic m-0">(50% bij overlijden):</p>
                                <h5 class="text-center m-2 fw-bold h5-product">{{ $numberFormatEuro(showCovSubgroup?.payout_once ?? 0, 0) }}</h5>
                                <p class="text-center small m-0">in één keer</p>
                                <div class="icon-container">
                                    <i class="fa fa-plus icon"></i>
                                </div>

                            </div>

                            <div class="col-2">
                                <img src="@/assets/svg/plus.svg" class="product-alternation-icon float-end end-0 me-3 big-btn" @click="() => increaseCOV()" v-if="!dynamic.disabled_now && showCovSubgroupIndex + 1 < cov_subgroups.length" role="button">
                            </div>
                        </div>
                    </div>
                    <div :class="['btn d-inline-block ms-2 mt-0 mb-2', isCovValid ? 'btn-primary' : 'btn-warning']" @click="() => toggleStep('retirement', [], true)"><i :class="['fa', isCovValid ? 'fa-arrow-down' : 'fa-exclamation-triangle']"></i> Volgende stap</div>
                </div>

                <template v-if="!dynamic.disabled_now">
                    <!-- Instellen pensioenpot -->
                    <div :class="[['retirement', 'savings', 'schedule'].indexOf(showStep) >= 0 ? 'bg-white' : 'bg-non-selectable', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('retirement', ['savings', 'schedule'])" role="button">
                        <div class="col w-50 p-0">
                            <h4 class="py-3 m-0 fw-bold text-dark-blue">Pensioenpot</h4>
                        </div>
                        <div class="col w-50 p-0 position-relative">
                            <div class="d-flex float-end m-0 h-100 text-dark">
                                <span :class="['m-0 small my-auto', isRetirementValid ? '' : 'text-danger']">{{ $numberFormatEuro(show_retirement_after_directdebit_amount, 0) }} p/m</span>
                            </div>
                        </div>
                    </div>

                    <div class="bg-block subtle-border retirement w-100" v-if="dynamic.hasOwnProperty('retirement_amount_options')">
                        <h6 class="text-dark fw-bold text-center py-3 pb-1">Kies je pensioenopbouw</h6>
                        <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-3 position-relative animate-inputs-right">
                            <div class="row row-cols-3">
                                <div class="col-2">
                                    <img src="@/assets/svg/min2.svg" class="product-alternation-icon float-start ms-3 start-0 big-btn" @click="() => decreaseRetirementAfterDirectdebit()" v-if="show_retirement_after_directdebit_amount > 0" role="button">
                                </div>

                                <div class="col text-center text-dark w-100 pe-none">
                                    <div class="text-center mb-2">
                                        <div class="icon-products-container d-inline-block">
                                            <img class="icon-products" src="@/assets/icon-oldman.svg" alt="Gepensioneerde">
                                        </div>
                                    </div>
                                    <p class="text-center small font-italic m-0">Geen kosten</p>
                                    <p class="text-center small font-italic m-0">In je pensioenpot:</p>
                                    <h4 class="text-center text-info m-2 fw-bold">{{ $numberFormatEuro(show_retirement_after_directdebit_amount, 0) }}</h4>
                                    <p class="text-center small m-0">per maand</p>
                                    <p v-if="customer.iban_retirement === null && show_retirement_after_directdebit_amount > 0" class="text-center smaller m-0 mt-1">De pensioenopbouw start zodra uw pensioenrekening is geactiveerd.</p>
                                    <div v-if="customer.iban_retirement === null && show_retirement_after_directdebit_amount > 0" class="col text-center my-2 pe-auto">
                                        <router-link to="/retirement-account" class="btn btn-light"><i class="fa fa-link me-1"></i> IBAN pensioenspaarrekening aanvragen</router-link>
                                    </div>
                                    <div class="icon-container">
                                        <i class="fa fa-plus icon"></i>
                                    </div>

                                </div>

                                <div class="col-2">
                                    <img src="@/assets/svg/plus.svg" class="product-alternation-icon float-end end-0 me-3 big-btn" @click="() => increaseRetirementAfterDirectdebit()" v-if="show_retirement_after_directdebit_amount < Math.max(...dynamic.retirement_amount_options)" role="button">
                                </div>
                            </div>
                        </div>
                        <div :class="['btn d-inline-block ms-2 mt-0 mb-2', isRetirementValid ? 'btn-primary' : 'btn-warning']" @click="() => toggleStep('savings', [], true)"><i :class="['fa', isRetirementValid ? 'fa-arrow-down' : 'fa-exclamation-triangle']"></i> Volgende stap</div>
                    </div>

                    <!-- Spaarpot -->
                    <div :class="[['savings', 'schedule'].indexOf(showStep) >= 0 ? 'bg-white' : 'bg-non-selectable', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('savings', ['schedule'])" role="button">
                        <div class="col w-50 p-0">
                            <h4 class="py-3 m-0 fw-bold text-dark-blue">Sparen</h4>
                        </div>
                        <div class="col w-50 p-0 position-relative">
                            <div class="d-flex float-end m-0 h-100 text-dark">
                                <span :class="['m-0 small my-auto fade-in', isCostGreaterThanPayment ? 'text-danger' : '']" :key="savings_after_directdebit_amount">{{ $numberFormatEuro(savings_after_directdebit_amount, 0) }} p/m</span>
                            </div>
                        </div>
                    </div>

                    <div class="bg-block subtle-border savings w-100" >
                        <h6 class="text-dark fw-bold text-center py-3 pb-1">Het verwachte dat bedrag je maandelijks opspaart</h6>
                        <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-3 position-relative animate-inputs-right">
                            <div class="row row-cols-3">
                                <div class="col text-center text-dark w-100 pe-none">
                                    <div class="text-center mb-2">
                                        <div class="icon-products-container d-inline-block">
                                            <img class="icon-products" src="@/assets/icon-piggy.svg" alt="Gepensioneerde">
                                        </div>
                                    </div>
                                    <h4 class="text-center m-2 mt-3 text-info fw-bold d-inline-block rounded-1  h4-product">{{ $numberFormatEuro(savings_after_directdebit_amount, 0) }} p/m</h4>
                                    <p class="text-center small font-italic m-0">Geen kosten</p>
                                    <p class="text-center small m-0">Verander je maandelijkse inleg of je productsamenstelling om dit bedrag aan te passen</p>
                                    <div class="icon-container">
                                        <i class="fa fa-plus icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-primary d-inline-block ms-2 mt-0 mb-2" @click="() => toggleStep('schedule', [], true)"><i class="fa fa-arrow-down"></i> Volgende stap</div>
                    </div>
                </template>
                
                <template v-if="dynamic.disabled_now">
                    <div :class="[['savings', 'retirement', 'schedule'].indexOf(showStep) >= 0 ? 'bg-white' : 'bg-non-selectable', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('retirement', ['savings', 'schedule'])" role="button">
                        <div class="col w-50 p-0">
                            <h4 class="py-3 m-0 fw-bold text-dark-blue">Pensioen van schenking</h4>
                        </div>
                        <div class="col w-50 p-0 position-relative">
                            <div class="d-flex float-end m-0 h-100 text-dark">
                                <span class="m-0 small my-auto">{{ $numberFormatEuro(show_retirement_after_payment_amount, 0) }} p/m</span>
                            </div>
                        </div>
                    </div>

                    <div class="bg-block subtle-border retirement w-100" v-if="dynamic.hasOwnProperty('retirement_amount_options')">
                        <h6 class="text-dark fw-bold text-center py-3 pb-1">Kies je pensioenopbouw tijdens het ontvangen van schenkingen</h6>
                        <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-3 position-relative animate-inputs-right">
                            <div class="row row-cols-3">
                                <div class="col-2">
                                    <img src="@/assets/svg/min2.svg" class="product-alternation-icon float-start ms-3 start-0 big-btn" @click="() => decreaseRetirementAfterPayment()" v-if="show_retirement_after_payment_amount > 0" role="button">
                                </div>

                                <div class="col text-center text-dark w-100 pe-none">
                                    <div class="text-center mb-2">
                                        <div class="icon-products-container d-inline-block">
                                            <img class="icon-products" src="@/assets/icon-oldman.svg" alt="Gepensioneerde">
                                        </div>
                                    </div>
                                    <p class="text-center small font-italic m-0">Geen kosten</p>
                                    <p class="text-center small font-italic m-0">In je pensioenpot:</p>
                                    <h4 class="text-center text-info m-2 fw-bold">{{ $numberFormatEuro(show_retirement_after_payment_amount, 0) }}</h4>
                                    <p class="text-center small m-0">per maand</p>
                                    <p v-if="customer.iban_retirement === null && show_retirement_after_payment_amount > 0" class="text-center smaller m-0 mt-1">De pensioenopbouw start zodra uw pensioenrekening is geactiveerd.</p>
                                    <div v-if="customer.iban_retirement === null && show_retirement_after_payment_amount > 0" class="col text-center my-2 pe-auto">
                                        <router-link to="/retirement-account" class="btn btn-light"><i class="fa fa-link me-1"></i> IBAN pensioenspaarrekening aanvragen</router-link>
                                    </div>
                                    <div class="icon-container">
                                        <i class="fa fa-plus icon"></i>
                                    </div>

                                </div>

                                <div class="col-2">
                                    <img src="@/assets/svg/plus.svg" class="product-alternation-icon float-end end-0 me-3 big-btn" @click="() => increaseRetirementAfterPayment()" v-if="show_retirement_after_payment_amount < Math.max(...dynamic.retirement_amount_options) && this.show_retirement_after_payment_amount < this.show_retirement_after_directdebit_amount" role="button">
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-primary d-inline-block ms-2 mt-0 mb-2" @click="() => toggleStep('savings', [], true)"><i class="fa fa-arrow-down"></i> Volgende stap</div>
                    </div>


                    <div :class="[['savings', 'schedule'].indexOf(showStep) >= 0 ? 'bg-white' : 'bg-non-selectable', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('savings', ['savings', 'schedule'])" role="button">
                        <div class="col w-50 p-0">
                            <h4 class="py-3 m-0 fw-bold text-dark-blue">Sparen van schenking</h4>
                        </div>
                        <div class="col w-50 p-0 position-relative">
                            <div class="d-flex float-end m-0 h-100 text-dark">
                                <span class="m-0 small my-auto">{{ $numberFormatEuro(show_savings_during_payment_amount, 0) }} p/m</span>
                            </div>
                        </div>
                    </div>

                    <div class="bg-block subtle-border savings w-100" >
                        <h6 class="text-dark fw-bold text-center py-3 pb-1">Het verwachte bedrag je maandelijks opspaart van de ontvangen schenkingen</h6>
                        <div class="row row-cols-1 mx-2 justify-content-center my-4 mt-3 position-relative animate-inputs-right">
                            <div class="row row-cols-3">
                                <div class="col-2">
                                    <img src="@/assets/svg/min2.svg" class="product-alternation-icon float-start ms-3 start-0 big-btn" @click="() => decreaseSavingsDuringPayment()" v-if="show_savings_during_payment_amount > 0" role="button">
                                </div>

                                <div class="col text-center text-dark w-100 pe-none">
                                    <div class="text-center mb-2">
                                        <div class="icon-products-container d-inline-block">
                                            <img class="icon-products" src="@/assets/icon-piggy.svg" alt="Gepensioneerde">
                                        </div>
                                    </div>
                                    <h4 class="text-center m-2 mt-3 text-info fw-bold d-inline-block rounded-1  h4-product">{{ $numberFormatEuro(show_savings_during_payment_amount, 0) }} p/m</h4>
                                    <p class="text-center small font-italic m-0">Geen kosten</p>

                                    <div class="icon-container">
                                        <i class="fa fa-plus icon"></i>
                                    </div>
                                </div>

                                <div class="col-2">
                                    <img src="@/assets/svg/plus.svg" class="product-alternation-icon float-end end-0 me-3 big-btn" @click="() => increaseSavingsDuringPayment()" v-if="show_savings_during_payment_amount < savings_after_directdebit_amount" role="button">
                                </div>
                            </div>
                        </div>
                        <div class="btn btn-primary d-inline-block ms-2 mt-0 mb-2" @click="() => toggleStep('schedule', [], true)"><i class="fa fa-arrow-down"></i> Volgende stap</div>
                    </div>
                </template>

                <!-- Ingangsdatum -->
                <div :class="[['schedule'].indexOf(showStep) >= 0 ? 'bg-block-alt' : 'bg-non-selectable-block-alt', 'subtle-border d-flex w-100 position-relative px-3 selection-border']" @click="() => toggleStep('schedule')" role="button">
                    <div class="col w-50 p-0">
                        <h4 class="py-3 m-0 fw-bold text-dark-blue">Ingangsdatum</h4>
                    </div>
                    <div class="col w-50 p-0 position-relative">
                        <div class="d-flex float-end m-0 h-100 text-dark">
                            <span class="m-0 small my-auto change-at">{{ dynamic.change_at ? $formatDate(dynamic.change_at) : '' }}</span>
                        </div>
                    </div>
                </div>

                <div class="bg-block subtle-border schedule w-100" v-if="changeAtOptions">
                    <h6 class="text-dark fw-bold text-center py-3 px-3 pb-1">Kies de datum waarop je gekozen producten ingaan</h6>
                    <div class="row row-cols-1">
                        <div class="col px-5 mt-1 mb-2">
                            <select class="form-select" id="dateStart" v-model="change_at">
                                <option v-for="date in changeAtOptions" :key="date" :value="date">
                                    {{ displayStartDate(date) }}
                                </option>
                            </select>
                        </div>

                        <div class="col px-5 mb-3">
                            <div class="btn btn-primary" :disabled="!isPaymentValid" @click="submitChanges()"><i class="fas fa-paper-plane me-1"></i> Bevestig mijn keuzes</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Modal v-for="modal in modals" :key="modal.title" :title="modal.title" :content="modal.content"
        :button_text="modal.button_text" :button_icon="modal.button_icon" :url="modal.url" :modal_type="modal.modal_type" :question_enum="modal.question_enum" :additional_class="modal.additional_class"
        @closeModal="closeModal" @storeNewProducts="storeNewProducts" />
</template>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import { getCustomerStorage } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Modal from '@/components/ModalComponent.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "products-change-page",
    components: {
        Breadcrumbs,
        Modal,
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/products', 'Mijn producten'), new Breadcrumb('/products/change', 'Wijzigen')],

            dynamic: {},
            now: new Date(new Date().setHours(0,0,0,0)),
            change_at: 0,
            changeAtOptions: [],
            selected: new Date(new Date().setHours(0,0,0,0)),
            changes: [],
            showStep: 'payment',

            options: ['aov', 'cov', 'retirement', 'savings', 'payment', 'schedule'],

            showPaymentSubgroup: null,
            showPaymentSubgroupIndex: 0,

            showAovSubgroup: null,
            showAovSubgroupIndex: 0,
            
            showCovSubgroup: null,
            showCovSubgroupIndex: -1,

            show_retirement_after_payment_amount: 0,
            show_retirement_after_directdebit_amount: 0,

            show_savings_during_payment_amount: 0,

            aov_subgroups: [],
            cov_subgroups: [],
            payment_subgroups: [],
            modals: [],

            customer: {},
        }
    },
    methods: {
        toggleChange(change) {
            this.showPaymentSubgroup = null;
            this.showPaymentSubgroupIndex = 0;
            
            this.showAovSubgroup = null;
            this.showAovSubgroupIndex = 0;
            
            this.showCovSubgroup = null;
            this.showCovSubgroupIndex = -1;

            this.show_retirement_after_directdebit_amount = 0;
            this.show_retirement_after_payment_amount = 0;

            this.show_savings_during_payment_amount = 0;

            this.dynamic.participants.forEach(function(participant) {
                const start_at = new Date(participant.start_at),
                    end_at = participant.end_at ? new Date(participant.end_at) : null;

                if(start_at <= change && (!end_at || end_at >= change)) {
                    
                    this.payment_subgroups.forEach(function(subgroup, subgroupIndex) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showPaymentSubgroup = subgroup;
                            this.showPaymentSubgroupIndex = subgroupIndex;

                            if(participant.active_payout_requests.length) {
                                participant.active_payout_requests.forEach(function(payout_request) {
                                    if(payout_request.type == 'payout_after_directdebit_sure' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_directdebit_amount = payout_request.amount;
                                    } else if(payout_request.type == 'payout_after_payment' && payout_request.user_iban == 'retirement') {
                                        this.show_retirement_after_payment_amount = payout_request.amount;
                                    } else if(payout_request.type == 'savings_during_payment' && payout_request.user_iban == null) {
                                        this.show_savings_during_payment_amount = payout_request.amount;
                                    }

                                }.bind(this));
                            }
                        }
                    }.bind(this));

                    this.aov_subgroups.forEach(function(subgroup, subgroupIndex) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showAovSubgroup = subgroup;
                            this.showAovSubgroupIndex = subgroupIndex;
                        }
                    }.bind(this));

                    this.cov_subgroups.forEach(function(subgroup, subgroupIndex) {
                        if(subgroup.id == participant.subgroup_id) {
                            this.showCovSubgroup = subgroup;
                            this.showCovSubgroupIndex = subgroupIndex;
                        }
                    }.bind(this));
                }
            }.bind(this));

        },

        showPaymentNotifications() {
            if(this.showAovSubgroup.available_from_payment_monthly > this.showPaymentSubgroup?.subgroup_payment_monthy)
                $(".min-aov").show(400);
            else
                $(".min-aov").hide(400);
        },

        decreaseAOV() {
            if(this.showAovSubgroupIndex > 0) {
                this.showAovSubgroupIndex--;
            }

            this.showAovSubgroup = this.aov_subgroups[this.showAovSubgroupIndex];
            this.showPaymentNotifications();
        },
        increaseAOV() {
            if(this.showAovSubgroupIndex + 1 < this.aov_subgroups.length) {
                this.showAovSubgroupIndex++;
            }

            this.showAovSubgroup = this.aov_subgroups[this.showAovSubgroupIndex];
            this.showPaymentNotifications();
        },

        decreaseCOV() {
            if(this.showCovSubgroupIndex > 0) {
                this.showCovSubgroupIndex--;
                this.showCovSubgroup = this.cov_subgroups[this.showCovSubgroupIndex];
            } else if(this.showCovSubgroupIndex === 0) {
                this.showCovSubgroupIndex = -1;
                this.showCovSubgroup = null;
            }

        },
        increaseCOV() {
            if(this.showCovSubgroupIndex + 1 < this.cov_subgroups.length) {
                this.showCovSubgroupIndex++;
            }

            this.showCovSubgroup = this.cov_subgroups[this.showCovSubgroupIndex];
        },

        decreaseRetirementAfterDirectdebit() {
            if(this.show_retirement_after_directdebit_amount > 0) {
                this.show_retirement_after_directdebit_amount -= 5;
            }
        },
        increaseRetirementAfterDirectdebit() {
            if(this.show_retirement_after_directdebit_amount < Math.max(...this.dynamic.retirement_amount_options)) {
                this.show_retirement_after_directdebit_amount += 5;
            }
        },

        decreaseRetirementAfterPayment() {
            if(this.show_retirement_after_payment_amount > 0) {
                this.show_retirement_after_payment_amount -= 5;
            }
        },
        increaseRetirementAfterPayment() {
            if(this.show_retirement_after_payment_amount < Math.max(...this.dynamic.retirement_amount_options) && this.show_retirement_after_payment_amount < this.show_retirement_after_directdebit_amount) {
                this.show_retirement_after_payment_amount += 5;
            }
        },
        decreaseSavingsDuringPayment() {
            if(this.show_savings_during_payment_amount > 0) {
                this.show_savings_during_payment_amount -= 5;
            }
        },
        increaseSavingsDuringPayment() {
            if(this.show_savings_during_payment_amount < this.savings_after_directdebit_amount) {
                this.show_savings_during_payment_amount += 5;
            }
        },

        decreasePayment() {
            if(this.showPaymentSubgroupIndex > 0) {
                this.showPaymentSubgroupIndex--;
            }

            this.showPaymentSubgroup = this.payment_subgroups[this.showPaymentSubgroupIndex];
            this.showPaymentNotifications();
        },
        increasePayment() {
            if(this.showPaymentSubgroupIndex + 1 < this.payment_subgroups.length) {
                this.showPaymentSubgroupIndex++;
            }

            this.showPaymentSubgroup = this.payment_subgroups[this.showPaymentSubgroupIndex];
            this.showPaymentNotifications();
        },

        displayStartDate(date) {
            let d = new Date(date);
            let months = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
            
            return d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
        },

        closeModal() {
            this.modals[0].additional_class = 'disappear';
            setTimeout(() => {
                this.modals = [];
            }, 500);
        },

        showErrorModal(unavailableProduct = false) {
            this.modals.push(this.$createModal(unavailableProduct ? "Product niet beschikbaar voor inleg" : "Inleg te laag", "Verhoog je maandelijkse inleg of kies een ander product om deze productsamenstelling te kiezen.", "fa-check", "OK", "/", this.$ModalType.Notification));
        },

        toggleStep(currentStep, productArray = [], forceNextStep = false) {   
            switch(this.showStep) {
                case 'aov':
                    if(!this.isAovValid && currentStep === 'cov') {
                        this.showErrorModal(this.showAovSubgroup.available_from_payment_monthly > this.showPaymentSubgroup?.subgroup_payment_monthy);
                        return;
                    }
                    break;
                case 'cov':
                    if(!this.isCovValid && currentStep === 'retirement') {
                        this.showErrorModal();
                        return;
                    }
                    break;
                case 'retirement':
                    if(!this.isRetirementValid && currentStep === 'savings') {
                        this.showErrorModal();
                        return;
                    }
                    break;
            }

            // if(forceNextStep) {
            //     if(!this.isPaymentValid && currentStep !== 'aov') {
            //         this.modals.push(this.$createModal("Inleg te laag", "Verhoog je maandelijkse inleg of kies een ander product om deze productsamenstelling te kiezen.", "fa-check", "OK", "/", this.$ModalType.Notification));
            //         return;
            //     }
            // }
            
            // showStep = (['retirement', 'savings', 'payment', 'schedule'].indexOf(showStep) >= 0) ? 'cov' : showStep ";
            if(currentStep === 'payment') {
                this.showStep = currentStep;
                $('.payment').show('slow');
                this.options.forEach((option) => {
                    let st = `.${option}`;
                    if(st !== '.payment') {
                        $(st).hide('slow');
                    }
                });
                return;
            }

            if((productArray.indexOf(this.showStep) >= 0) || forceNextStep) {
                this.showStep = currentStep;
                this.options.forEach((option) => {
                    let st = `.${option}`;
                    if(st === `.${currentStep}`) {
                        if($(st).is(":visible")) {
                            $(st).hide('slow');
                        } else {
                            $(st).show('slow');
                        }
                    } else {
                        $(st).hide('slow');
                    }
                });
            }
        },

        submitChanges() {
            if(!this.isPaymentValid) {
                const toast = useToast();
                toast.error("Je maandelijkse inleg is te laag om de huidige productsamenstelling af te sluiten.");
                return;
            }

            let hasChangesFromSelectedChangeAt = false;
            this.changes.forEach(function(change) {
                if(+change >= +(new Date(this.change_at))) {
                    hasChangesFromSelectedChangeAt = true;
                }
            }.bind(this));

            // Laat een modal zien om de gebruiker te vragen om de gekozen producten toe te passen
            let title = hasChangesFromSelectedChangeAt ? "Let op!" : "Productsamenstelling wijzigen";
            let message = hasChangesFromSelectedChangeAt ? "Er zijn al eerder wijzigingen gekozen vanaf de geselecteerde datum. Wil je deze laten vervallen?" : "Weet je zeker dat je deze productsamenstelling wilt hanteren?";
            this.modals.push(this.$createModal(title, message, "fa-check", "Ja", "/products", this.$ModalType.Question, "storeNewProducts"));
        },

        storeNewProducts() {
            this.$http.post('/participants/dynamic', {
                    change_at: (new Date(this.change_at)).toJSON(),
                    
                    aov_subgroup_id: this.showAovSubgroup.id,
                    cov_subgroup_id: this.showCovSubgroup?.id,
                    payment_subgroup_id: this.showPaymentSubgroup.id,

                    retirement_after_directdebit_amount: this.show_retirement_after_directdebit_amount,
                    retirement_after_payment_amount: this.show_retirement_after_payment_amount,
                    savings_during_payment_amount: this.show_savings_during_payment_amount,
                }).
                then(() => {
                    this.$router.push('/products');
                });
        }
    },
    computed: {
        isPaymentValid: function() {
            let minPayment = (this.showAovSubgroup?.expected_monthly_costs ?? 0) + (this.showCovSubgroup?.monthly_costs ?? 0) + (this.show_retirement_after_directdebit_amount ?? 0);
            if(this.showAovSubgroup?.available_from_payment_monthly && minPayment < this.showAovSubgroup.available_from_payment_monthly) {
                minPayment = this.showAovSubgroup.available_from_payment_monthly
            }
            if(this.showCovSubgroup?.available_from_payment_monthly && minPayment < this.showCovSubgroup.available_from_payment_monthly) {
                minPayment = this.showAovSubgroup.available_from_payment_monthly
            }
            
            return Math.floor(minPayment) <= Math.floor(this.showPaymentSubgroup?.subgroup_payment_monthy ?? 0);
        },

        savings_after_directdebit_amount: function() {
            let costs = (this.showAovSubgroup?.expected_monthly_costs ?? 0) + (this.showCovSubgroup?.monthly_costs ?? 0) + (this.show_retirement_after_directdebit_amount ?? 0);
            let payment = (this.showPaymentSubgroup?.subgroup_payment_monthy ?? 0)

            return payment - costs;
        },

        isAovValid: function() {
            // Als product niet beschikbaar is voor maandelijkse inleg
            if(this.showAovSubgroup?.available_from_payment_monthly > this.showPaymentSubgroup?.subgroup_payment_monthy)
                return false;
            if(this.showAovSubgroup?.expected_monthly_costs > this.showPaymentSubgroup?.subgroup_payment_monthy)
                return false;
            return true;
        },

        isCovValid: function() {
            // Als product niet beschikbaar is voor maandelijkse inleg
            if(this.showCovSubgroup?.available_from_payment_monthly > this.showPaymentSubgroup?.subgroup_payment_monthy)
                return false;
            if((this.showAovSubgroup?.expected_monthly_costs + this.showCovSubgroup?.monthly_costs) > this.showPaymentSubgroup?.subgroup_payment_monthy)
                return false;
            return true;
        },

        isRetirementValid: function() {
            if(this.isCostGreaterThanPayment)
                return false;
            return true;
        },

        isCostGreaterThanPayment() {
            if(this.savings_after_directdebit_amount < 0)
                return true;
            return (this.showAovSubgroup?.expected_monthly_costs ?? 0) + (this.showCovSubgroup?.monthly_costs ?? 0) + (this.show_retirement_after_directdebit_amount ?? 0) > this.showPaymentSubgroup?.subgroup_payment_monthy;
        }
    },
    async mounted() {
        const response = await this.$http.get('/participants/dynamic');
        this.dynamic = response.data;

        let customer = getCustomerStorage().getItem("customer");
        if(!customer) {
            console.log("Customer not found");
            return;
        }
        this.customer = JSON.parse(customer);


        this.now = new Date(new Date().setHours(0,0,0,0));
        this.change_at = +(new Date(this.dynamic.change_at));

        for(let i = 0; i < 4; i++) {
            let date = new Date(this.dynamic.change_at);
            date.setMonth(date.getMonth() + i)
            this.changeAtOptions.push(+date);
        }

        this.payment_subgroups = this.dynamic.payment_subgroups.filter(function(subgroup) {
            if(subgroup.end_at && new Date(subgroup.end_at) < this.change_at) {
                return false;
            } else {
                return true;
            }
        }.bind(this));
        
        this.aov_subgroups = this.dynamic.aov_subgroups.filter(function(subgroup) {
            if(subgroup.end_at && new Date(subgroup.end_at) < this.change_at && !this.dynamic.disabled_now) {
                return false;
            } else {
                return true;
            }
        }.bind(this));
        
        this.cov_subgroups = this.dynamic.cov_subgroups.filter(function(subgroup) {
            if(subgroup.end_at && new Date(subgroup.end_at) < this.change_at && !this.dynamic.disabled_now) {
                return false;
            } else {
                return true;
            }
        }.bind(this));


        // STAP 1 breakpoinst bepalen
        this.changes = [this.now];
        this.dynamic.participants.forEach(function(participant) {
            const start_at = new Date(participant.start_at),
                end_at = participant.end_at ? new Date(participant.end_at) : null;

            // opv start, in toekomst, of niet lege end
            if(start_at > this.now) {
                let change = new Date(start_at);
                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }
            if(end_at) {
                let change = new Date(end_at);
                change.setDate(change.getDate() + 1);

                if(this.changes.map(Number).indexOf(+change) == -1) {
                    this.changes.push(change);
                }
            }

        }.bind(this));        

        // STAP 1 huidig bepalen
        this.toggleChange(this.now);

        if((!this.showAovSubgroup || !this.showPaymentSubgroup) && this.changes.map(Number).indexOf(+new Date(this.dynamic.change_at)) !== -1) {
            this.toggleChange(new Date(this.dynamic.change_at));
        }
        if(!this.showAovSubgroup && this.aov_subgroups.length) {
            this.showAovSubgroupIndex = 0;
            this.showAovSubgroup = this.aov_subgroups[0];
        }
        if(!this.showPaymentSubgroup && this.payment_subgroups.length) {
            this.showPaymentSubgroupIndex = 0;
            this.showPaymentSubgroup = this.payment_subgroups[0];
        }

        $(".animate-inputs").hide();
        setTimeout(() => {
            // Sluit alle tabjes na stap 1
            this.options.forEach((option) => {
                if(option !== 'payment')
                    $(`.${option}`).hide();
            });
            $(".animate-inputs").show(400);
            $(".min-aov").hide(0);
        }, 300);

        $("#dateStart").on('change', function() {
            let date = new Date(parseInt($("#dateStart").find(":selected").val()));
            $(".change-at").text([date.getDate() < 10 ? '0'+  date.getDate() : date.getDate(), date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1, date.getFullYear()].join("-"));
        });
    }
}
</script>

<style scoped>
.fa-minus,
.fa-plus {
    cursor: pointer;
}

h4 {
    transition: color var(--transition-400ms) ease;
}

.too-long {
    display: inline-block;
    width: 100%;
    word-wrap: break-word;
}

.fa-chevron-down {
    font-size: 42px;
}

.selection-border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bg-block {
    background-color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05) inset;
}

.opacity-anim {
    transition: opacity var(--transition-400ms) ease;
}

.rot-180 {
    transform: rotateZ(180deg);
}

.svg-icon {
    height: 1em;
    color: #fff;
    margin-left: 0.5em;
    transition: transform 600ms;
    opacity: 80%;
}

.vertical-align {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 0;
    text-align: center;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.bg-gray {
  
  background-color: #ccc;
}

.big-btn {
    width: 1.5em;
    position: absolute;
    bottom: calc(50% - 0.75em);
}

hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.bg-white, .bg-non-selectable {
    transition: background-color var(--transition-400ms) ease;
}

.bg-non-selectable {
    background-color: #ddd;
    cursor: default;
}

.text-dark-blue {
    color: var(--dark-blue);
}

.subtle-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.indicator {
    width: 9px;
    height: 9px;
    padding: 0;
    margin: 0;
    position: relative;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #fff;
}

.h5-product {
    color: var(--dark-blue) !important;
}

.icon-products-container {
    /* padding: 2em; */
    /* border-radius: 50%;
    background: linear-gradient(-45deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6));
    border: 1px dotted rgba(0, 26, 105, 0.3); */
}

.indicator-text {
    color: #fff;
    font-weight: thin !important;
    font-size: 10px;
    left: 20px;
    top: -21x;
    position: absolute;
    display: none;
}

.smaller {
    font-size: 11px;
}

h4 {
    font-size: calc(16px + 0.4vw);
}

.bg-block-alt {
    background-color: #cae4ff;
}

.bg-non-selectable-block-alt {
    background-color: #aabfd5;
    cursor: default;
}
</style>
