<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h4 class="display-4 text-white fw-normal mb-0">Mijn ziekmeldingen</h4>

            <!-- Ziek en beter melden -->
            <div class="row p-2 position-relative">
                <div class="col p-1">
                    <router-link to="/disability/new" class="btn btn-warning"><i class="fa fa-stethoscope"></i> Ziek melden</router-link>
                </div>
            </div>

            <hr>

            <!-- Lopende ziekmeldingen -->
            <div class="row position-relative">
                <h4 class="display-6 text-white fw-normal">Lopende ziekmeldingen</h4>
                <p class="text-white" v-if="hasCurrentDisabilities">Klik op een ziekmelding om een update te geven.</p>
            </div>

            <!-- Uitleg over AO-percentage -->
            <div class="rounded-2 text-start small text-dark bg-info w-100 ao-info position-relative">
                <div class="p-3">
                    <i class="fa fa-info-circle me-1"></i> Dit is het verloop van je arbeidsongeschiktheid op basis van bevindingen en vastleggingen van een <u>arbo</u>&#8209;arts of de casemanager.
                </div>
            </div>

            <div id="currentDisabilities" class="position-relative w-100">
                <div class="row p-3 position-relative">
                    <table class="table table-hover table-responsive" v-if="hasCurrentDisabilities">
                        <thead>
                            <tr>
                                <th class="text-white d-table-cell">Sinds</th>
                                <th class="text-white d-table-cell" @click="toggleAoInfo" role="button">AO-Percentage <i class="ms-1 fa fa-question-circle text-info"></i> </th>
                                <th class="text-white d-table-cell">
                                    <span class="d-none d-md-table-cell">Update gewenst op</span>
                                    <span class="d-table-cell d-md-none">Update</span>
                                </th>
                                <th class="text-white d-none d-md-table-cell"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="disability in currentDisabilities" :key="disability.id" class="position-relative">
                                <td class="text-white small d-table-cell" @click="() => this.$router.push(`/disability/update/${disability.id}`)">{{ $formatDate(disability.start_at) }}</td>
                                <td class="text-white small d-table-cell position-relative" @click="() => this.$router.push(`/disability/update/${disability.id}`)">
                                    <div v-if="disability.course_of_disabilities && hasCurrentDisabilities && disability.course_of_disabilities[disability.course_of_disabilities.length - 1] && (disability.course_of_disabilities[disability.course_of_disabilities.length - 1].disability_part * 100) > 0">
                                        <p class="position-absolute text-center m-0 mt-3 w-100 small pe-2">{{ disability.course_of_disabilities.length > 0 ? disability.course_of_disabilities[disability.course_of_disabilities.length - 1].disability_part * 100 : 0 }}&percnt;</p>
                                        <progress class="mt-1" :value="disability.course_of_disabilities.length > 0 ? disability.course_of_disabilities[disability.course_of_disabilities.length - 1].disability_part * 100 : 0" min="0" max="100"></progress>
                                    </div>
                                    <div class="d-flex position-relative" v-else>
                                        <i class="fa fa-hourglass text-info me-2 position-absolute"></i><p class="small m-0 ms-3">Geen percentage</p>
                                    </div>
                                </td>
                                <td class="text-white d-table-cell" @click="() => this.$router.push(`/disability/update/${disability.id}`)">
                                    <img src="@/assets/update.svg" class="icon-new" v-if="isExpectingUpdate(disability)">
                                    <p class="m-0 small" v-else>{{ disability.customer_update_expected_at ? $formatDate(disability.customer_update_expected_at) : 'Niet van toepassing' }}</p>
                                </td>
                                <td class="text-white small text-start d-none d-md-table-cell" @click="() => this.$router.push(`/disability/update/${disability.id}`)">
                                    <i class="fa fa-edit"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Pagination -->
                <div id="pagination" v-if="currentDisabilities.length">
                    <Pagination :pagination="pagination" :currentPaginationPage="currentPaginationPage" @updatePagination="(url, label) => paginate(url, label)"/>
                </div>

                <p class="p-3 bg-info text-dark small rounded-2" v-if="!hasCurrentDisabilities">
                    <i class="fa fa-info-circle me-1"></i> Je hebt op dit moment geen lopende ziekmeldingen
                </p>
            </div>

             <!-- Afgeronde ziekmeldingen -->
             <div class="row mb-2">
                <div class="col">
                    <button class="btn btn-primary" v-if="hasPastDisabilities" @click="() => toggleCloseddisabilities()"><i :class="['fa fa-chevron-down me-1', showCloseddisabilities ? 'close-chevron' : '']"></i> {{ showCloseddisabilities ? 'Verberg historie' : 'Toon historie' }}</button>
                </div>
             </div>

             <div id="closeddisabilities" class="w-100">
                <div class="row position-relative">
                    <h4 class="display-6 fw-normal text-white">Afgeronde ziekmeldingen</h4>
                </div>

                <div class="row p-3 position-relative">
                    <table class="table table-hover table-responsive">
                        <thead>
                            <tr>
                                <th class="text-white">Start</th>
                                <th class="text-white">Einde</th>
                                <th class="text-white">Beschrijving</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="disability in pastDisabilities" :key="disability.id">
                                <td class="text-white">{{ $formatDate(disability.start_at) }}</td>
                                <td class="text-white">{{ $formatDate(disability.end_at) }}</td>
                                <td class="text-white">{{ disability.description }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bg-green {
    background-color: var(--light-green);
}

.fa-hourglass {
    top: calc(50% - 0.5em);
}

.notif-new {
    position:absolute;
    left: -5px;
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    background-color: var(--warning);
}

progress {
    width: 100%;
}

.fa-chevron-down {
    transform: 0;
    transition: transform var(--transition-400ms) ease-out;
}

.close-chevron {
    transform: rotateZ(180deg);
}

.bg-yellow {
    background-color: var(--light-yellow);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.update {
    color: var(--light-blue);
}

td {
    cursor: pointer;
}

table tr td,
table tr,
th {
    background-color: transparent !important;
}

.add {
    position: absolute;
    left: 6px;
    top: 6px;
    font-size: 20px;
    font-weight: 100;
}

.h3-product {
    font-weight: 800;
    font-size: 18px;
}

.h4-product {
    font-weight: bold;
    font-size: 16px;
    color: #00B1C5;
}

.h5-product {
    font-weight: bold;
    font-size: 18px;
}

.smaller {
    font-size: 9px;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}
</style>

<script type="text/javascript">
import $ from 'jquery';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';
import { Breadcrumb } from '@/App.vue';
// import { getCustomerStorage } from '@/App.vue';

export default {
    name: 'disability-component',
    components: {
        Breadcrumbs,
        Pagination,
    },
    data() {
        return {
            disabilities: [],
            responseData: null,
            showCloseddisabilities: false,
            breadcrumbs: [new Breadcrumb("/disability", "Mijn ziekmeldingen")],
            disabilityCustomerUpdates: [],
            pagination: [],
            currentPaginationPage: "1",
        }
    },
    methods: {
        toggleCloseddisabilities() {
            this.showCloseddisabilities = !this.showCloseddisabilities;
            if(this.showCloseddisabilities)
                $('#closeddisabilities').show(400);
            else
                $('#closeddisabilities').hide(400);
        },
        paginate(url, label) {
            if(!url)
                return;

            if(!this.$canUsePagination(url, label))
                return;

            this.$http.get(url)
                .then((response) => {
                    this.responseData = response.data;
                    this.disabilities = this.responseData.data;
                    this.pagination = this.responseData.links;

                    this.pagination[0].label = "<";
                    this.pagination[this.pagination.length - 1].label = ">";
                    
                    this.currentPaginationPage = this.responseData.current_page.toString();
                });
        },
        isExpectingUpdate(disability) {
            if(!disability.customer_update_expected_at)
                return false;

            return new Date(disability.customer_update_expected_at) < new Date();
        },
        toggleAoInfo() {
            $(".ao-info").toggle(400);
        },
    },
    computed: {
        currentDisabilities() {
            return this.disabilities.filter(disability => disability.end_at === null);
        },
        pastDisabilities() {
            return this.disabilities.filter(disability => disability.end_at !== null);
        },
        hasPastDisabilities() {
            let list = this.disabilities.filter(disability => disability.end_at !== null);
            return list.length > 0
        },
        hasCurrentDisabilities() {
            let list = this.disabilities.filter(disability => disability.end_at === null);
            return list.length > 0
        },
    },
    mounted() {
        $('#closeddisabilities').hide();
        $('#currentDisabilities').hide();
        $(".ao-info").hide();
        $("#pagination").hide();

        // Haal ziekmeldingen op uit de database
        try {
            this.$http.get('disabilities')
                .then((response) => {
                    this.responseData = response.data;
                    this.disabilities = this.responseData.data;
                    this.disabilityCustomerUpdates = this.disabilities.disability_customer_updates;

                    // Set pagination
                    this.pagination = this.responseData.links;
                    this.currentPaginationPage = this.responseData.current_page.toString();

                    this.pagination[0].label = "<";
                    this.pagination[this.pagination.length - 1].label = ">";

                    $("#pagination").show(400);
                    
                    $("#currentDisabilities").show(400);
            });
        } catch(error) {
            console.log(error);
        }
    }
}
</script>
