<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />
            <h4 class="display-4 fw-normal text-white">Nieuwe ziekmelding</h4>
            <hr>
            <form id="disabilityForm" @submit.prevent="submit">
                <p class="p-3 rounded-2 bg-info text-dark text-start small w-100 appear"><i class="fa fa-info-circle me-1"></i>
                    Meld je direct ziek via onderstaand formulier. Je eerste ziektedag is namelijk de dag dat je je hebt
                    ziekgemeld. Dat is ook de dag waarop de wachttijd van twee maanden ingaat. Je kunt je niet met
                    terugwerkende kracht ziekmelden.
                </p>

                <p class="p-3 rounded-2 bg-warning text-dark text-start small w-100 appear"><i class="fa fa-exclamation me-1"></i>
                    Meld je wel pas ziek als je door ziekte (deels) niet meer kunt werken. Je kunt je niet ziekmelden
                    met
                    een datum in de toekomst. Voorwaarde voor ziekmelden is dat je in ieder geval gedeeltelijk niet aan
                    het
                    werk bent. Geef bij je ziekmelding aan welk percentage dit is.
                </p>

                <!-- Start ziektebeeld -->
                <div class="d-flex">
                    <div class="w-100">
                        <h6 class="text-white">Start ziektebeeld</h6>
                    </div>
                    <div class="w-100 mb-3">
                        <h6 id="startDate" class="text-white fw-normal text-end">{{ today }}</h6>
                    </div>
                </div>

                <!-- Beschrijving -->
                <div class="d-block appear w-100 mb-2">
                    <h6 class="text-white">Beschrijving</h6>
                    <textarea class="form-control" rows="4" placeholder="Beschrijf je ziekmelding hier" v-model="description"></textarea>
                </div>

                <!-- Behandeling bij arts -->
                <h6 class="text-white">Ben je onder behandeling bij een arts?</h6>
                <div class="d-flex ps-1">
                    <input type="radio" name="doctor" value="1" class="mb-3" v-model="treatmentByDoctor">
                    <p class="text-white ms-2 me-4">Ja</p>

                    <input type="radio" name="doctor" value="0" class="mb-3" v-model="treatmentByDoctor">
                    <p class="text-white ms-2">Nee</p>
                </div>

                <!-- Zelfde oorzaak eerdere ziekmelding? -->
                <h6 class="text-white">Heeft deze ziekmelding dezelfde oorzaak als een eerdere ziekmelding?</h6>
                <div class="d-flex ps-1">
                    <input type="radio" name="samesickness" value="Ja" class="mb-3" @change="() => toggleCauses(1)">
                    <p class="text-white ms-2 me-4">Ja</p>

                    <input type="radio" name="samesickness" value="Nee" class="mb-3" checked @change="() => toggleCauses(0)">
                    <p class="text-white ms-2">Nee</p>
                </div>

                <!-- Vorige oorzaken -->
                <div id="previousCauses">
                    <div class="mb-3">
                        <h6 class="text-white">Vorige oorzaken</h6>
                        <select class="form-select" id="connectedDisability">
                            <option value="">-- Selecteer een ziekmelding --</option>
                            <option v-for="disability in previousDisabilites" :key="disability.id" :value="disability.id">
                                {{ disability.created_at }} {{ disability.description }}
                            </option>
                        </select>
                    </div>
                </div>

                <h6 class="text-white">Wanneer verwacht je weer te gaan werken?</h6>
                <input type="date" id="expectedEndDate" class="form-control" v-model="expectedEndAt" >

                <div class="d-block d-md-flex mt-3 appear">
                    <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> Ziekmelding toevoegen</button>
                </div>
            </form>
        </div>
    </div>
    <Modal v-for="modal in modals" :key="modal.title" :title="modal.title" :content="modal.content"
        :button_text="modal.button_text" :button_icon="modal.button_icon" :url="modal.url" :modal_type="modal.modal_type" :question_enum="modal.question_enum" :additional_class="modal.additional_class"
        @closeModal="closeModal" @sendDisability="sendDisability"/>
</template>

<style scoped>
h6 {
    font-weight: 800;
}

input[type="radio"] {
    transform: scale(1.5);
}

p {
    text-align: right;
}

.tl {
    text-align: left !important;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
    text-align: left;
}

.bg-transparent {
    background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>

<script>
import $ from 'jquery';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Modal from '@/components/ModalComponent.vue';
import { Breadcrumb } from '@/App.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "personalia-component",
    components: {
        Breadcrumbs,
        Modal,
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/disability', "Arbeidsongeschiktheid"), new Breadcrumb("/disability/new", "Nieuwe ziekmelding")],
            today: this.convertDate(new Date()),
            previousDisabilites: [],
            expectedEndAt: null,
            treatmentByDoctor: 0,
            description: null,
            connectedDisability: null,
            modals: [],
        }
    },
    methods: {
        convertDate(date) {
            let day, month, year;
            day = date.getDate();
            month = date.getMonth();
            year = date.getFullYear();
            return `${day < 10 ? "0" + day : day}-${(month + 1) < 10 ? "0" + (month + 1) : month + 1}-${year}`;
        },
        toggleCauses(display) {
            if(display)
                $("#previousCauses").show(400);
            else
                $("#previousCauses").hide(400);
        },
        async submit() {
            // Verzend ziekmelding naar de backend
            try {
                if(!this.description || this.description === '') {
                    const toast = useToast();
                    toast.error("Vul alsjeblieft een beschrijving in", {
                        icon: "fa fa-times"
                    });
                    return;
                }

                if(this.expectedEndAt && new Date(this.expectedEndAt).getTime() < new Date().getTime()) {
                    const toast = useToast();
                    toast.error("Vul alsjeblieft een datum in de toekomst in", {
                        icon: "fa fa-times"
                    });
                    return;
                }

                this.modals.push(this.$createModal("Ziekmelding versturen", "Weet je zeker dat je deze ziekmelding wilt versturen?", "fa-check", "Ja", "/disability", this.$ModalType.Question, "sendDisability"));
            } catch(error) {
                console.log(error);
            }
        },
        async sendDisability() {
            try {
                await this.$http.post('disabilities/add', {
                    "expected_end_at": this.expectedEndAt,
                    "under_treatment_by_a_doctor": this.treatmentByDoctor,
                    "description": this.description,
                    "connected_disability_id": $("#connectedDisability").find(":selected").val()
                })
                .then((response) => {
                    // Zet een timer om niet te snel naar de volgende route te gaan
                    this.$router.push(`/disability/confirmation/${response.data.id}`);
                });
            } catch(error) {
                console.log(error);
            }
        },
        closeModal() {
            this.modals[0].additional_class = 'disappear';
            setTimeout(() => {
                this.modals = [];
            }, 500);
        },
        async getDisabilities() {
            try {
                this.$http.get('disabilities')
                .then((response) => {
                    // Haal ziekmeldingen op om vorige oorzaken toe te voegen
                    response.data.data.forEach((element) => {
                        if(element.description) {
                            this.previousDisabilites.push(element);
                        }
                    });
                });
            } catch(error) {
                console.log(error);
            }
        },
    },
    mounted() {
        $("#previousCauses").hide();

        $(".appear").hide();
        $(".appear").show(400);

        this.getDisabilities();
    }
}
</script>