<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="text-white display-4 fw-normal">Statistieken</h1>

            <hr />

            <h3 class="text-white display-6 fw-normal">Arbeidsongeschiktheidspercentage</h3>

            <p class="p-3 bg-trans-black text-light rounded-3 small">
                <i class="fa fa-info-circle me-1"></i> Dit is het percentage van alle deelnemers dat momenteel een lopende
                ziekmelding heeft en recht heeft op een schenking. Jíj draagt met je deelname bij aan een inkomen voor deze ondernemers.
            </p>

            <div class="mb-3 d-flex">
                <div class="d-block">
                    <h3 class="text-white display-1 fw-bold m-0">{{dashboardData && dashboardData.current_effective_disability_percentage ? dashboardData.current_effective_disability_percentage.toString().replaceAll(".", ",") : '' }}&percnt;</h3>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col justify-center position-relative text-center">

                    <div class="pie-chart">
                        <div class="pie-circle" :style="'--p:' + dashboardData.current_effective_disability_percentage"></div>

                        <div class="pie-tooltip">
                            {{dashboardData && dashboardData.current_effective_disability_percentage ? (100 - dashboardData.current_effective_disability_percentage).toString().replaceAll(".", ",") : '' }}&percnt; Gezond
                        </div>
                    </div>
                    
                </div>
            </div>

            <p class="p-3 bg-info text-black mt-2 rounded-2 small"><i class="fa fa-notes-medical me-1"></i> Voordat we overgaan
                tot schenking aan de zieke deelnemers controleren we of de deelnemer inderdaad recht heeft op een schenking. In
                geval van ziekte ondersteunen we de deelnemer in zijn re-integratieproces.</p>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { Breadcrumb } from '@/App.vue';


// https://vue-chartjs.org/guide/#installation
export default {
    name: "ao-percentage",
    components: {
        Breadcrumbs
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb("/ao-percentage", "Statistieken")],

            dashboardData: [],

            // chartData:  {
            //     labels: ['Ziek', 'Gezond'],
            //     datasets: [
            //         {
            //             backgroundColor: ['#ffffff', '#ffffff66'],
            //             borderWidth: [0, 0],
            //             data: [0, 0]
            //         }
            //     ]
            // },
        }
    },

    methods: {
        async fetchDashboardData() {
            try {
                await this.$http.get('dashboard')
                .then((response) => {
                    this.dashboardData = response.data;

                    // this.chartData = {
                    //     labels: ['Ziek', 'Gezond'],
                    //     datasets: [
                    //         {
                    //             backgroundColor: ['#ffffff', '#ffffff66'],
                    //             borderWidth: [0, 0],
                    //             data: [
                    //                 this.dashboardData.current_effective_disability_percentage,
                    //                 100 - this.dashboardData.current_effective_disability_percentage
                    //             ]
                    //         }
                    //     ]
                    // };
                });
            } catch (error) {
                console.error(error.response?.data?.errors)
                console.error(error)
            }
        }
    },
    mounted() {
        $(".pie-chart").fadeOut(0);
        $(".pie-chart").fadeIn(1000);

        this.fetchDashboardData();
    },
}

// const xValues = ["Ziektepercentage", ""];
// const yValues = [5, 95];
// const barColors = ['#0dcaf0', '#000'];

// new Chart("chart", {
//     type: "pie",
//     data: {
//         labels: xValues,
//         datasets: [{
//             backgroundColor: barColors,
//             data: yValues
//         }]
//     }, options: {
//         title: {
//             display: false
//         }
//     }
// });
</script>

<style scoped>
hr {
    border-color: rgba(255, 255, 255, 0.2);
}

.bb1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bb2 {
    border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

a {
    text-decoration: none;
}

.bg-trans-black {
    background-color: rgba(0, 0, 0, 0.2);
}
.pie-chart {
    display: block;
    position: relative;
}

.pie-tooltip {
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 25%;
    left: 50%;
    margin-left: -60px;
}
.pie-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.pie-circle {
    background: conic-gradient(#fff 0, #fff calc(var(--p)*1%), #82a1c1 calc(var(--p)*1%), #82a1c1 100%);
    border-radius: 100%;
    
    position: relative;
    
    width: 65vw;
    height: 65vw;

    max-width: 400px;
    max-height: 400px;
    
    margin: auto;
}


 @keyframes rotate-into-view {
    0% {
        transform: rotateZ(-50deg);
    }

    100% {
        transform: 0;
    }
 }
</style>