<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3 m-0">
            <Breadcrumbs :breadcrumbs="breadcrumbs"></Breadcrumbs>
            <h1 class="text-white display-4 fw-normal">Authenticator instellen</h1>
            <hr />
            <div class="fade-all position-relative">
                <p class="text-white">Gebruik de QR code hiernaast om uw Google Authenticator in te stellen.</p>
                <ol class="text-white border-subtle position-relative py-3 pe-3">
                    <li>Download de Google Authenticator app op uw mobile device.<br/>
                        <div class="my-2 d-flex">
                            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="btn btn-light me-1"><i class="fab fa-google-play me-1"></i> Play Store</a>
                            <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" target="_blank" class="btn btn-dark"><i class="fab fa-apple store me-1"></i> App Store</a>
                        </div>
                    </li>
                    <li>Open de app <span class="d-none d-md-inline-block fw-normal">of scan de QR code met uw mobiele apparaat</span> en<br class="d-none d-md-block"/> kopieer de code {{ authenticatorSecret }} in de Google Authenticator.<br/>
                        <div class="my-2 d-flex">
                            <a :href="authenticatorLink" target="_blank" class="btn btn-primary"><i class="fa fa-link me-1"></i> Open de app</a>
                        </div>
                    </li>
                    
                    <li>Voer de 6 cijferige code van de Google Authenticator app in om de instellingen te valideren.</li>
                    <a :href="authenticatorLink" class="qr-code authenticator-qr d-none d-md-flex" target="_blank">
                        <img v-if="authenticatorLink" :src="'https://api.qrserver.com/v1/create-qr-code/?size=160x160&data=' + authenticatorLink +'&choe=utf-8'">
                    </a>
                </ol>

                <hr />

                <form id="authenticatorSetup" @submit.prevent="submit">
                    <div class="row position-relative my-3">
                        <div class="col-3">
                            <label for="newPassword" class="text-white fw-bold mt-2">Code</label>
                        </div>
                        <div class="col-9">
                            <input type="password" id="authenticatorCode" class="form-control w-100" placeholder="Code" :value="authenticatorCode" @input="(event) => authenticatorCode = event.target.value" >
                        </div>
                    </div>

                    <button class="btn btn-primary"><i class="fa fa-unlock me-1"></i> Activeren</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import { useToast } from 'vue-toastification';

export default {
    name: "setup-authenticator",
    components: {
        Breadcrumbs,
    },
    data() {
        return {
            breadcrumbs: [new Breadcrumb('/account', 'Account'), new Breadcrumb('/account/setup-authenticator', 'Google Authenticator')],
            authenticatorCode: "",
            authenticatorLink: "",
            secret: "",
        }
    },
    methods: {
        async submit() {
            const toast = useToast();
            await this.$http.post('set_up/authenticator', { 
                passcode: this.authenticatorCode,
            }).then((response) => {
                this.authenticatorCode = "";

                toast.success(response.data["alert-success"], {
                    icon: "fa fa-check",
                });

                this.$router.push('/account');
            }).catch(() => {
                this.authenticatorCode = "";
            });
        },
    },
    async mounted() {
        // Vervangen door echte naam app en secret (misschien API call?)
        // TODO: Zetten secret in session voor Laravel
        this.$http.get('/get_link/authenticator')
            .then((response) => {
                this.authenticatorLink = response.data.link;
                this.authenticatorSecret = response.data.secret;
            })
            .catch((err) => {
                const toast = useToast();
                toast.error("We kunnen je Authenticator niet instellen. Probeer het later nog eens.", {
                    icon: "fa fa-frown",
                });
                console.log(err);
            });
        
        // Laat alle objecten zachtjes infaden
        $(".fade-all").fadeOut(0);
        $(".fade-all").fadeIn('slow');
    }
}
</script>

<style scoped>
.border-subtle {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.qr-code {
    right: 0;
    top: 0;
    position: absolute;
}
</style>