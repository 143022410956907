<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h4 class="display-4 fw-normal mb-3 text-white">Schenkingen</h4>
            
            <hr/>

            <p class="p-3 bg-light text-dark rounded-2 small"><i class="fa fa-info-circle me-1"></i>
                Hieronder zie je een overzicht van de schenkingen die je hebt gedaan aan de zieke deelnemers. Hiermee helpen
                we elkaar aan inkomen bij arbeidsongeschiktheid.
            </p>

            <div class="position-relative gifts-table w-100">
                <table class="table position-relative text-white" v-if="gifts.length > 0">
                    <thead>
                        <tr>
                            <th class="text-white">Periode</th>
                            <th class="text-white">Aan deelnemers</th>
                            <th class="text-right text-white">Totaal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="gift in gifts" :key="gift.id">
                            <td :class="new Date(gift.period_end_at) > new Date() ? 'text-muted' : 'text-white'"><strong>{{ $formatDate(gift.period_start_at) }}</strong> tot <strong>{{ $formatDate(gift.period_end_at) }}</strong></td>
                            <td :class="new Date(gift.period_end_at) > new Date() ? 'text-muted' : 'text-white'">{{ gift.sum_participants }}</td>
                            <td :class="(new Date(gift.period_end_at) > new Date() ? 'text-muted' : 'text-white') + ' text-right'">{{ $numberFormatEuro(-1 * gift.sum_amount) }}</td>
                        </tr>
                    </tbody>
                </table>

                <p class="p-3 rounded-2 bg-info text-dark small" v-else>
                    <i class="fa fa-info-circle me-1"></i> Er zijn nog geen schenkingen naar andere gebruikers gegaan.
                </p>

                <div class="mb-3" v-if="gifts.length > 0">
                    <Pagination :pagination="pagination" :currentPaginationPage="currentPaginationPage" @updatePagination="(url, label) => loadGifts(url, label)"/>
                </div>
            </div>

            <h4 class="display-6 fw-normal text-white">Totaal geschonken</h4>
            <div class="row mb-3">
                <div class="col">
                    <select class="form-select" @change="changeMonths" id="monthsSelection">
                        <option v-for="[key, value] in Object.entries(monthOptions)" :key="value" :value="value">{{ key }}</option>
                    </select>
                </div>
            </div>
            <Bar :data="chartData" :options="chartOptions" :key="compKey" style="height:min(100vw,80vh,400px);"/>

            <p class="p-3 bg-info text-black mt-2 rounded-2 small"><i class="fa fa-heart me-1"></i> Met deze schenkingen, waaraan jij hebt bijgedragen, heb je tientallen deelnemers voorzien van inkomen tijdens hun arbeidsongeschiktheid!</p>
        </div>
    </div>
</template>

<style scoped>
.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

h3 {
    position: absolute;
    font-size: 16px;
    bottom: 0;
    font-weight: 800;
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

table tr td,
table tr,
th {
    background-color: transparent !important;
}

.text-right {
    text-align: right;
}
</style>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import Pagination from '@/components/PaginationComponent.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import 'chartjs-adapter-moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Colors,
)

ChartJS.defaults.color = "#fff";
ChartJS.defaults.font.family = "Lato";

export default {
    name: 'gifts-component',
    components: {
        Breadcrumbs,
        Pagination,
        Bar,
    },
    data() {
        return {
            gifts: [],
            breadcrumbs: [new Breadcrumb("/gifts", "Schenkingen")],
            currentPaginationPage: "1",
            monthOptions: {
                "6 maanden": 6,
                "1 jaar": 12,
                "2 jaar": 24,
                "5 jaar": 60
            },
            compKey: 0,
            months: 6,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false,
                        font: {
                            family: "Lato"
                        }
                    },
                    colors: {
                        enabled: true
                    },
                    font: {
                        family: "Lato"
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                return new Intl.NumberFormat('nl-NL', {
                                    style: 'currency',
                                    currency: 'EUR',
                                    maximumFractionDigits: 1, 
                                    minimumFractionDigits: 1,
                                }).format(value).replace(/\s/g, '');
                            }
                        },
                    }
                }
                // scales: {
                    
                //     y: {
                //         title: {
                //             display: true,
                //             text: 'Totaal geschonken (€)'
                //         },
                //         grid: {
                //             color: "rgba(255, 255, 255, 0.3)",
                //             borderColor: "rgba(255, 255, 255, 0.3)"
                //         }
                //     }
                // }
            },
            chartData: {
                labels: [],
                datasets: [],
            },
        }
    },
    methods: {
        async loadGifts(url = "", label = "") {
            const httpUrl = url === "" ? 'payout_given' : url;

            if(!this.$canUsePagination(url, label))
                return;

            await this.$http.get(httpUrl)
                .then((response) => {
                    this.gifts = response.data.data;
                    this.pagination = response.data.links;

                    this.currentPaginationPage = response.data.current_page.toString();

                    this.pagination[0].label = "<";
                    this.pagination[this.pagination.length - 1].label = ">";
                });

            this.loadPayoutFlow();
        },
        async loadPayoutFlow() {
            await this.$http.get('payout_given_flow/' + this.months)
                .then((response) => {
                    // for(let i = 0; i < response.data.datasets.data.length; i++) {
                    //     response.data.datasets.data[i].y *= -1;
                    // }
                    // response.data.datasets = [response.data.datasets];
                    this.chartData = response.data;
                    $(".gifts-table").show('slow');
                });
        },
        changeMonths() {
            this.months = $("#monthsSelection").find(":selected").val();
            this.loadPayoutFlow();
        },
    },
    mounted() {
        $(".gifts-table").hide();
        this.loadGifts();
    }
}
</script>
