<template>
    <div class="w-100 m-0 d-flex justify-content-center">
        <div class="container-inputs p-3">
            <Breadcrumbs :breadcrumbs="breadcrumbs" />

            <h1 class="display-4 text-white fw-normal">Mijn spaarpot</h1>
            <hr />

            <p class="p-3 bg-light text-dark rounded-2 small">
                <i class="fa fa-info-circle me-1"></i> Dit is het bedrag dat je op dit moment hebt gespaard, te weten het totaalbedrag van je maandelijkse inleg minus de aftrek van de kosten van je afgenomen producten.
            </p>

            <div class="row row-cols-md-2 row-cols-1 mt-2">
                <div class="col mb-md-0 mb-2">
                    <router-link to="/saldo/withdraw" class="btn btn-primary"><i class="fa fa-euro-sign me-1"></i> Saldo opnemen</router-link>
                </div>
            </div>

            <h4 class="display-6 text-white mt-3 fw-normal">Saldo</h4>

            <h3 class="mb-0 p-3 bg-trans display-1 text-light rounded-4 display-1 position-relative">
                <div>
                    <span :class="['d-block max-balance', loadedBalance ? 'text-white' : 'text-transparent'] " id="balance">
                        {{ this.$numberFormatEuro(totalSaldo) }}
                    </span>
                </div>

                <div>
                    <img src="@/assets/icons/loading.png" alt="Loading icon" class="loading-icon">
                </div>
            </h3>

            <h4 class="display-6 text-white mt-3 fw-normal">Saldoverloop</h4>

            <div class="row m-0 p-0 position-relative justify-content-center w-100 line-chart">
                <Line :data="chartData" :options="chartOptions" :key="compKey" style="height:min(100vw,80vh,400px);"/>
                <i class="fa fa-circle-notch fa-spin"></i>
                <!-- <p class="position-middle rounded-2 p-3 small bg-info text-dark no-results-period"><i class="fa fa-info-circle me-1"></i> Er zijn geen resultaten voor deze periode.</p> -->
            </div>

            <div class="row row-cols-1 mt-2">
                <div class="col"><h5 class="text-white">Terugkijken</h5></div>
                <div class="col">
                    <select class="form-select" id="monthsSelection" @change="getCustomerData">
                        <option v-for="[key, value] in Object.entries(monthOptions)" :value="value" :key="key">{{ key }}</option>
                    </select>
                </div>
                
            </div>

            <h4 class="display-6 text-white mt-3 fw-normal">Transactieoverzicht</h4>
            <p class="p-3 bg-info text-dark rounded-2 small">
                <i class="fa fa-info-circle me-1"></i> Bekijk de transacties van de geselecteerde deelname
            </p>
            <div class="btn btn-primary mb-3" @click="toggleTransactions"><i class="fa fa-list-alt"></i> {{ isOpen ?
                'Transactieoverzicht verbergen' : 'Transactieoverzicht bekijken' }}</div>

            <div id="transactions" class="position-relative w-100">
                <div v-if="transactions.length > 0">
                    <table class="table position-relative">
                        <thead>
                            <tr>
                                <th class="text-white px-0 px-md-2">Transactie datum</th>
                                <th class="text-white px-0 px-md-2 text-center text-md-end">Bedrag</th>
                                <th class="text-white px-0 px-md-2 text-end">Saldo na transactie</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="transaction in transactions" :key="transaction.date">
                                <tr :class="['position-relative', loadingTransactions ? 'opacity-0' : '']">
                                    <td class="no-border px-0 px-md-2 text-white fw-md-normal fw-bold">{{$formatDate(transaction.expected_at)}}</td>
                                    <td class="no-border px-0 px-md-2 text-white text-md-end text-center">{{this.$numberFormatEuro(parseFloat(transaction.sum_amount))}}</td>
                                    <td class="no-border px-0 px-md-2 text-white text-end">
                                        <span class="fw-normal" v-if="transaction.saldo_after_grouped_customer">{{ this.$numberFormatEuro(transaction.saldo_after_grouped_customer) }}</span>
                                        <i v-if="transaction.saldo_after_grouped_customer == null" class="fa fa-hourglass-half"></i>
                                        <i :class="['middle-ver d-none d-sm-inline-block ms-2', transaction.sum_amount > 0 ? 'text-info far fa-arrow-alt-circle-up' : 'text-danger far fa-arrow-alt-circle-down']"></i>
                                    </td>
                                </tr>
                                <tr :class="['position-relative', loadingTransactions ? 'opacity-0' : '']">
                                    <td :class="['text-white border-subtle px-0 px-md-2 pt-0 small', loadingTransactions ? 'border-transparent' : '']" colspan="3">{{ transaction.description }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    
                    <!-- Pagination -->
                    <Pagination :pagination="transactionPagination" :currentPaginationPage="currentPaginationPage" @updatePagination="(url, label) => fetchTransactions(url, label)"/>
                </div>

                <p v-else class="p-3 rounded-2 small bg-info text-dark"><i class="fa fa-info-circle me-1"></i> Er zijn geen resultaten gevonden</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.no-border, .border-subtle {
    border-color: rgba(255, 255, 255, 0.25);
}

.no-border {
    border-color: transparent;
}

.back {
    color: #fff !important;
    opacity: 75%;
    font-size: 12px;
}

.position-middle {
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 37.5px);
    width: 200px;
    height: 75px;
}

.border-transparent {
    border-color: transparent;
}

span {
    transition: color 500ms;
}

hr {
    border-color: rgba(255, 255, 255, 0.5);
}

.hide {
    max-height: 0;
}

table tr td,
table tr,
th {
    background-color: transparent !important;
}

.fa-circle-notch {
    font-size: 128px;
    position: absolute;
    left: calc(50% - 64px);
    top: calc(50% - 64px);
    color: #fff;
    transform-origin: 76px;
}

.loading-icon {
    filter: brightness(2000%);
    position: absolute;
    width: 1em;
    height: 1em;
    animation: spinning 2s ease-in-out infinite;
    top: calc(50% - 0.5em);
    transform-origin: 50%;
}

@keyframes spinning {
    0% {
        transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

.bg-trans {
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
}

.text-transparent {
    color: transparent;
}

tr {
    transition: opacity var(--transition-400ms) ease-in-out;
}

td {
    transition: border-color var(--transition-400ms) ease-in-out;
}

.bg-green {
    background-color: var(--light-green);
}

.middle-ver {
    top: calc(50% - 0.5em);
    right: 0.3em;
}

.bg-no-balance {
    background-color: rgba(255, 199, 199);
}
</style>

<script type="text/javascript">
import $ from 'jquery';
import { Breadcrumb } from '@/App.vue';
import Breadcrumbs from '@/components/BreadcrumbsComponent.vue';
import Pagination from '@/components/PaginationComponent.vue';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js'
import { Line } from 'vue-chartjs'
import 'chartjs-adapter-moment';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
)

ChartJS.defaults.color = "#fff";
ChartJS.defaults.font.family = "Lato";

export default {
    name: 'saldo-component',
    components: {
        Breadcrumbs,
        Line,
        Pagination
    },
    data() {
        return {
            payoutAmount: null,
            isOpen: true,
            breadcrumbs: [new Breadcrumb("/saldo", "Mijn spaarpot")],
            loadedBalance: false,
            transactions: [],
            transactionPagination: [],
            currentPaginationPage: '1',
            loadingTransactions: false,
            participants: [],
            totalSaldo: 0,
            compKey: 0,
            transactionProcesses: {
                "payout": "Uitbetaling",
                "directdebit": "Incasso",
                "internal": "Intern"
            },
            monthOptions: {
                "6 maanden": 6,
                "1 jaar": 12,
                "2 jaar": 24,
                "5 jaar": 60
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    colors: {
                        enabled: true
                    },
                },
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            // Luxon format string
                            tooltipFormat: 'DD-MM-YYYY'
                        },
                        title: {
                            display: true,
                            text: 'Datum'
                        },
                        grid: {
                            color: "rgba(255, 255, 255, 0.3)",
                            borderColor: "rgba(255, 255, 255, 0.3)"
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Saldo'
                        },
                        grid: {
                            color: "rgba(255, 255, 255, 0.3)",
                            borderColor: "rgba(255, 255, 255, 0.3)"
                        }
                    }
                }
            },
            chartData: {
                labels: {},
                datasets: [],
            }
        }
    },
    methods: {
        async fetchTransactions(url = "", label = "") {
            if(label === "...")
                return;

            if((label === "<" || label === ">") && url === null)
                return;

            this.loadingTransactions = true;
            let http = url !== "" ? url : 'transactions';

            this.$http.get(http)
                .then((response) => {
                    if(response.data.current_page)
                        this.currentPaginationPage = response.data.current_page.toString();
                    setTimeout(() => {
                        if(response.data.data) {
                            this.loadingTransactions = false;
                            this.transactions = response.data.data;
                            this.transactionPagination = response.data.links;
                            this.compKey++;

                            $(".line-chart").fadeIn(400);

                            // Verander labels voor vorige en volgende
                            this.transactionPagination[0].label = "<";
                            this.transactionPagination[this.transactionPagination.length - 1].label = ">";

                            $(".loading-icon").fadeOut("fast");
                        }
                    }, 250);
                });
        },
        async getCustomerData() {
            if(this.participants.length === 0)
                return;
            
            $(".fa-circle-notch").fadeIn(300);

            let query = `/${$("#monthsSelection").find(":selected").val()}`;
            await this.$http.get('transactions/saldo_flow' + query)
                .then((response) => {
                    $(".fa-circle-notch").fadeOut(300);

                    this.chartData = response.data;
                    $(".no-results-period").fadeIn("slow");
                    this.chartData.datasets.forEach((element) => {
                        if(element.data.length > 0)
                            $(".no-results-period").fadeOut(0);
                    })
                    this.fetchTransactions();
                });
        },
        toggleTransactions() {
            $("#transactions").toggle("fast");
            this.isOpen = !this.isOpen;
        }
    },
    mounted() {
        $(".fa-circle-notch").fadeOut(0);
        $(".change-participant").hide();
        $(".line-chart").fadeOut(0);
        $(".no-results-period").fadeOut(0);


        this.$http.get('/participants/all')
            .then((response) => {
                this.totalSaldo = 0;
                for(let i = 0; i < response.data.length; i++) {
                    const feature = response.data[i].subgroup.group.feature;
                    if(feature === 'to_user_saldo' || feature === 'aov_particiant_saldo') {
                        this.participants.push(response.data[i]);                        
                        this.totalSaldo += parseFloat(response.data[i].saldo);
                    }
                }

                if(this.participants.length > 0 && !this.loadedBalance) {
                    this.getCustomerData();
                    this.loadedBalance = true;
                }

                $(".loading-icon").fadeOut("slow");
                $("#balance").removeClass("text-transparent");
                $("#balance").addClass("text-light");
            });
    }
}
</script>
